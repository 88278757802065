import React, { useEffect } from "react";
import { Box, useTheme, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setFooterState } from "../features/footerSlice";
import { setBackState, increaseIndex } from "../features/navbarSlice";
import { cn } from '../utils/utils'

import FooterIcon11 from "../assets/images/footer_icon_1_1.svg";
import FooterIcon12 from "../assets/images/footer_icon_1_2.svg";
import FooterIcon21 from "../assets/images/footer_icon_2_1.svg";
import FooterIcon22 from "../assets/images/footer_icon_2_2.svg";
import FooterIcon31 from "../assets/images/footer_icon_3_1.svg";
import FooterIcon32 from "../assets/images/footer_icon_3_2.svg";
import FooterIcon41 from "../assets/images/footer_icon_4_1.svg";
import FooterIcon42 from "../assets/images/footer_icon_4_2.svg";

const Footer = () => {
  const currentFooter = useSelector((state) => state.footerState.value);
  const footerHidden = useSelector((state) => state.footerState.hidden);
  const gameState = useSelector((state) => state.gameState.value);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const handleChangeFooter = (num, redirectUrl) => {
    dispatch(setFooterState(num));
    const currentUrl =
      window.location.href.split("/")[
      window.location.href.split("/").length - 1
      ];
    dispatch(
      setBackState(
        currentUrl == "" ||
          currentUrl == " " ||
          currentUrl == "undefined" ||
          currentUrl == null
          ? "/"
          : "/" + currentUrl
      )
    );
    dispatch(increaseIndex());
    navigate(redirectUrl);
  };

  if(!!gameState || footerHidden) {
    return null;
  }

  return (
    <React.Fragment>
      <Box
        className="h-20 max-h-20"
        sx={{boxShadow: '0px -4px 28px 0px #A1A1A140'
        }}
      >
        <Box
          className="h-full w-full grid grid-cols-4" >
          <div className="flex justify-center items-center relative">
            {currentFooter === 1 && <div
                className={`absolute w-[75px] h-[75px] z-99 rounded-full bg-white left-1/2 -top-3 -translate-x-1/2`}></div>
            }
            <img
              className={cn("h-12 scale-75 z-100", currentFooter == 1 && "scale-125 -mt-5")}
              src={FooterIcon12}
              alt="Footer Icon"
              onClick={() => handleChangeFooter(1, "/")}
              style={{ cursor: "pointer" }}
            />
          </div>
          <div className="flex justify-center items-center relative">
            {currentFooter === 2 && <div
                className={`absolute w-[75px] h-[75px] z-99 rounded-full bg-white left-1/2 -top-3 -translate-x-1/2`}></div>
            }
            <img
              className={cn("h-12 scale-75", currentFooter == 2 && "scale-125 -mt-5")}
              src={FooterIcon22}
              alt="Footer Icon"
              onClick={() => handleChangeFooter(2, "/tournaments")}
              style={{ cursor: "pointer" }}
            />
          </div>
          <div className="flex justify-center items-center relative">
            {currentFooter === 3 && <div
                className={`absolute w-[75px] h-[75px] z-99 rounded-full bg-white left-1/2 -top-3 -translate-x-1/2`}></div>
            }
            <img
              className={cn("h-12 scale-75", currentFooter == 3 && "scale-125 -mt-5")}
              src={FooterIcon32}
              alt="Footer Icon"
              onClick={() => handleChangeFooter(3, "/shop_earn_gift")}
              style={{ cursor: "pointer" }}
            />
          </div>
          <div className="flex justify-center items-center relative">
            {currentFooter === 4 && <div
                className={`absolute w-[75px] h-[75px] z-99 rounded-full bg-white left-1/2 -top-3 -translate-x-1/2`}></div>
            }
            <img
              className={cn("h-12 scale-75", currentFooter == 4 && "scale-125 -mt-5")}
              src={FooterIcon42}
              alt="Footer Icon"
              onClick={() => handleChangeFooter(4, "/my_profile")}
              style={{ cursor: "pointer" }}
            />
          </div>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default Footer;
