import React from "react";
import { Box, useTheme, Button } from "@mui/material";
import Navbar from "../components/Navbar";
import MoneyCard from "../components/MoneyCard";
import LevelCard from "../components/LevelCard";

import ProfileIcon from "../assets/images/profile.png";
import Flag from "../assets/images/France_Flag.svg";
import MoneyIcon from "../assets/images/money_icon.svg";
import MoneyCardIcon from "../assets/images/money_card_icon.svg";
import Lock from "../assets/images/Lock.svg";
import GameCard from "../assets/images/level_card_3.svg";
import GameCard2 from "../assets/images/level_card_4.svg";
import Card1 from "../assets/images/level_card_1.svg";
import Star from "../assets/images/star.svg";
import Money from "../assets/images/level_money.svg";
import MoneyCardGroup from "../assets/images/level_card_2.svg";
import TournamentIcon from "../assets/images/level_7_icon.svg";
import UserProfileCard from "../components/UserProfileCard";

const Levels = () => {
  const theme = useTheme();

  return (
    <React.Fragment>
      <Navbar />
      <UserProfileCard />
      <Box sx={{ px: "21px", marginBottom: "14px" }}>
        <Box sx={{ background: "rgba(247, 249, 251, 1)", padding: "11px" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              px: "8px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "3px",
              }}
            >
              <img src={Star} alt="Star Icon" />
              <p
                style={{
                  fontSize: "12px",
                  fontWeight: "500",
                  lineHeight: "24px",
                }}
              >
                0
              </p>
            </Box>
            <p style={{ fontSize: "11px" }}>Your Reward</p>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              px: "8px",
              marginBottom: "14px",
            }}
          >
            <p
              style={{
                fontSize: "15px",
                fontWeight: "600",
                lineHeight: "24px",
              }}
            >
              Level 1
            </p>
            <p style={{ fontSize: "15px", fontWeight: "600" }}>
              Ticket Multiplier 1.0x
            </p>
          </Box>
          <LevelCard
            bg="linear-gradient(145.76deg, #232731 0%, #373C4C 100%)"
            keyIcon={Lock}
            title={"Unlock 5 Extra Games"}
            png={GameCard}
            starAmount="70"
          />
        </Box>
      </Box>
      <Box sx={{ px: "21px", marginBottom: "14px" }}>
        <Box sx={{ background: "rgba(247, 249, 251, 1)", padding: "11px" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              px: "8px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "3px",
              }}
            >
              <img src={Star} alt="Star Icon" />
              <p
                style={{
                  fontSize: "12px",
                  fontWeight: "500",
                  lineHeight: "24px",
                }}
              >
                30
              </p>
            </Box>
            <p style={{ fontSize: "11px" }}>Your Reward</p>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              px: "8px",
              marginBottom: "14px",
            }}
          >
            <p
              style={{
                fontSize: "15px",
                fontWeight: "600",
                lineHeight: "24px",
              }}
            >
              Level 2
            </p>
            <p style={{ fontSize: "15px", fontWeight: "600" }}>
              Ticket Multiplier 1.2x
            </p>
          </Box>
          <LevelCard
            bg="linear-gradient(145.76deg, #1EA4EC 0%, #049AEB 100%)"
            title={"Get 24 Tickets"}
            png={Card1}
          />
        </Box>
      </Box>
      <Box sx={{ px: "21px", marginBottom: "14px" }}>
        <Box sx={{ background: "rgba(247, 249, 251, 1)", padding: "11px" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              px: "8px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "3px",
              }}
            >
              <img src={Star} alt="Star Icon" />
              <p
                style={{
                  fontSize: "12px",
                  fontWeight: "500",
                  lineHeight: "24px",
                }}
              >
                70
              </p>
            </Box>
            <p style={{ fontSize: "11px" }}>Your Reward</p>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              px: "8px",
              marginBottom: "14px",
            }}
          >
            <p
              style={{
                fontSize: "15px",
                fontWeight: "600",
                lineHeight: "24px",
              }}
            >
              Level 3
            </p>
            <p style={{ fontSize: "15px", fontWeight: "600" }}>
              Ticket Multiplier 1.3x
            </p>
          </Box>
          <LevelCard
            bg="linear-gradient(145.76deg, #1EA4EC 0%, #049AEB 100%)"
            title={"Get 24 Tickets"}
            png={Card1}
          />
        </Box>
      </Box>
      <Box sx={{ px: "21px", marginBottom: "14px" }}>
        <Box sx={{ background: "rgba(247, 249, 251, 1)", padding: "11px" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              px: "8px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "3px",
              }}
            >
              <img src={Star} alt="Star Icon" />
              <p
                style={{
                  fontSize: "12px",
                  fontWeight: "500",
                  lineHeight: "24px",
                }}
              >
                140
              </p>
            </Box>
            <p style={{ fontSize: "11px" }}>Your Reward</p>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              px: "8px",
              marginBottom: "14px",
            }}
          >
            <p
              style={{
                fontSize: "15px",
                fontWeight: "600",
                lineHeight: "24px",
              }}
            >
              Level 4
            </p>
            <p style={{ fontSize: "15px", fontWeight: "600" }}>
              Ticket Multiplier 1.4x
            </p>
          </Box>
          <LevelCard
            bg="linear-gradient(145.76deg, #232731 0%, #373C4C 100%)"
            keyIcon={Lock}
            title={"Unlock 5 Extra Games"}
            png={GameCard}
            starAmount="70"
          />
          <LevelCard
            bg="linear-gradient(145.76deg, #78C885 0%, #40A86F 100%)"
            title={"Unlock Events"}
            png={Money}
            starAmount="70"
          />
          <LevelCard
            bg="linear-gradient(145.76deg, #1EA4EC 0%, #049AEB 100%)"
            title={"Get 56 Tickets"}
            png={Card1}
          />
        </Box>
      </Box>
      <Box sx={{ px: "21px", marginBottom: "14px" }}>
        <Box sx={{ background: "rgba(247, 249, 251, 1)", padding: "11px" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              px: "8px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "3px",
              }}
            >
              <img src={Star} alt="Star Icon" />
              <p
                style={{
                  fontSize: "12px",
                  fontWeight: "500",
                  lineHeight: "24px",
                }}
              >
                180
              </p>
            </Box>
            <p style={{ fontSize: "11px" }}>Your Reward</p>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              px: "8px",
              marginBottom: "14px",
            }}
          >
            <p
              style={{
                fontSize: "15px",
                fontWeight: "600",
                lineHeight: "24px",
              }}
            >
              Level 5
            </p>
            <p style={{ fontSize: "15px", fontWeight: "600" }}>
              Ticket Multiplier 1.5x
            </p>
          </Box>
          <LevelCard
            bg="linear-gradient(145.76deg, #232731 0%, #373C4C 100%)"
            keyIcon={Lock}
            title={"Unlock 2 Extra Games"}
            png={GameCard2}
            starAmount="70"
            level="5"
          />
          <LevelCard
            bg="linear-gradient(145.76deg, #1EA4EC 0%, #049AEB 100%)"
            title={"Get 75 Tickets"}
            png={Card1}
          />
        </Box>
      </Box>
      <Box sx={{ px: "21px", marginBottom: "14px" }}>
        <Box sx={{ background: "rgba(247, 249, 251, 1)", padding: "11px" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              px: "8px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "3px",
              }}
            >
              <img src={Star} alt="Star Icon" />
              <p
                style={{
                  fontSize: "12px",
                  fontWeight: "500",
                  lineHeight: "24px",
                }}
              >
                360
              </p>
            </Box>
            <p style={{ fontSize: "11px" }}>Your Reward</p>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              px: "8px",
              marginBottom: "14px",
            }}
          >
            <p
              style={{
                fontSize: "15px",
                fontWeight: "600",
                lineHeight: "24px",
              }}
            >
              Level 6
            </p>
            <p style={{ fontSize: "15px", fontWeight: "600" }}>
              Ticket Multiplier 1.6x
            </p>
          </Box>
          <LevelCard
            bg="linear-gradient(145.76deg, #232731 0%, #373C4C 100%)"
            keyIcon={Lock}
            title={"Unlock 6 Extra Games"}
            png={GameCard}
            starAmount="70"
          />
          <LevelCard
            bg="linear-gradient(145.76deg, #FC8F1A 0%, #FC901C 100%)"
            title={"Unlock Offerwall"}
            png={MoneyCardGroup}
            starAmount="70"
            level="6"
          />
          <LevelCard
            bg="linear-gradient(145.76deg, #1EA4EC 0%, #049AEB 100%)"
            title={"Get 75 Tickets"}
            png={Card1}
          />
        </Box>
      </Box>
      <Box sx={{ px: "21px", marginBottom: "14px" }}>
        <Box sx={{ background: "rgba(247, 249, 251, 1)", padding: "11px" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              px: "8px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "3px",
              }}
            >
              <img src={Star} alt="Star Icon" />
              <p
                style={{
                  fontSize: "12px",
                  fontWeight: "500",
                  lineHeight: "24px",
                }}
              >
                600
              </p>
            </Box>
            <p style={{ fontSize: "11px" }}>Your Reward</p>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              px: "8px",
              marginBottom: "14px",
            }}
          >
            <p
              style={{
                fontSize: "15px",
                fontWeight: "600",
                lineHeight: "24px",
              }}
            >
              Level 7
            </p>
            <p style={{ fontSize: "15px", fontWeight: "600" }}>
              Ticket Multiplier 1.7x
            </p>
          </Box>
          <LevelCard
            bg="linear-gradient(145.76deg, #FF583C 0%, #CC4527 100%)"
            title={"Unlock Tournaments"}
            png={TournamentIcon}
            starAmount="70"
            level="7"
          />
          <LevelCard
            bg="linear-gradient(145.76deg, #1EA4EC 0%, #049AEB 100%)"
            title={"Get 85 Tickets"}
            png={Card1}
          />
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default Levels;
