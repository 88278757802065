export const parseQueryString = (queryString) => {
  const params = {};
  const queryStringWithoutQuestionMark = queryString.slice(1); // Remove the leading '?'
  const keyValuePairs = queryStringWithoutQuestionMark.split("&");

  keyValuePairs.forEach((keyValue) => {
    const [key, value] = keyValue.split("=");
    params[key] = decodeURIComponent(value?.replace(/\+/g, " "));
  });

  return params;
};

export const parseQueryStartParam = (queryString) => {
  const params = parseQueryString(queryString);

  if (!params.tgWebAppStartParam) {
    return {};
  }

  return parseTgStartParam(params.tgWebAppStartParam);
}

export const parseTgStartParam = (start_param) => {
  if (!start_param?.length) {
    return;
  }
  
  const obj = {};
  const pairs = start_param.split('|');
  
  pairs.forEach(pair => {
    const [key, value] = pair.split(':');
    obj[key] = value;
  });

  return obj;
}

export function parseJwt(token) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}

export function toBase64(str) {
  return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function(match, p1) {
    return String.fromCharCode('0x' + p1);
  }));
}
