import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {Box, Button, useTheme, Typography} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {increaseIndex, setBackState} from "../features/navbarSlice";
import {getUserProfile} from "../middleware/userMiddleware";
import {setFooterState} from "../features/footerSlice";
import axios from "axios";

import Navbar from "../components/Navbar";
import DailyChecker from "../components/DailyChecker";
import DailyChallenge from "../components/DailyChallenge";
import DailyChallengeCard from "../components/DailyChallengeCard";

import WonProfileBg from "../assets/images/won_profile_bg.svg";
import BProfileIcon from "../assets/images/BProfileIcon.svg";
import PenIcon from "../assets/images/pen_icon.svg";
import Man1 from "../assets/images/man1.png";
import Man2 from "../assets/images/man2.png";
import Man3 from "../assets/images/man3.png";
import Man4 from "../assets/images/man4.svg";
import GoOver from "../assets/images/go_over_icon.svg";
import Gift from "../assets/images/gift.svg";
import Prize from "../assets/images/prize.png";
import BSquare from "../assets/images/BigSquare.svg";
import SSquare from "../assets/images/SmSquare.svg";

import Money from "../assets/images/p_money.png";
import Star from "../assets/images/p_star.png";
import Ton from "../assets/images/p_ton.png";

import ODailyIcon from "../assets/images/o_daily_icon.svg";
import DailyIcon from "../assets/images/daily_icon.svg";
import CircleChecker from "../assets/images/daily_circle_checker.svg";
import Deposit from "../assets/images/deposit.svg";
import Withdraw from "../assets/images/withdraw.svg";
import Redeem from "../assets/images/redeem.svg";
import Checker from "../assets/images/check.svg";
import Cross from "../assets/images/cross.svg";
import DChecker from "../assets/images/daily_checker.svg";
import Cup from "../assets/images/cup.svg";
import BDailyChallengeCard from "../assets/images/b_daily_challenge_card_1.svg";
import BlueCardIcon from "../assets/images/daily_blue_challenge_card_icon.svg";
import YelloCardIcon from "../assets/images/daily_yellow_challenge_card_icon.svg";

import {Countries, DailyChallengeData, FooterUrl, LanguageFlags,} from "../Constant";
import DailyChallengesCard from "../components/DailyChallengesCard";
import { SendTransactionRequest, useTonConnectUI, TonConnectButton, useTonWallet, useTonAddress } from '@tonconnect/ui-react';
import TaskForYou from "../components/TaskForYou";


const MyProfile = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [dailyData, setDailyData] = useState([]);
  const userProfile = useSelector((state) => state.user.userProfile);
  const [wallets, setWallets] = useState([]);
  //const { tonConnectUI } = useTonConnectUI();  // 获取 TonConnectUI 实例
  const [tonConnectUI, setOptions] = useTonConnectUI();
  const [ton_mount, setTon_mount] = useState(); 
  const [points, setPoints] = useState(); 
  const wallet = useTonWallet(); // 获取钱包对象
  const address = useTonAddress(); // 获取钱包地址

  

  const handleTransfer = async () => {
    if (!wallet) {
      console.error('No wallet connected');
      return;
    }

    const tx = {
      validUntil: Math.floor(Date.now() / 1000) + 600,
      messages: [
        {
          address: 'UQCrzFGbdBPtndRV35fDyQNkvKKeuK9nrtL2Wj872aJaJFvP', // 使用传递的地址
          amount: '100000' // 10 USDT, assuming 1 USDT = 1,000,000 nanoUSDT
        },
      ],
    };

    try {
      //await tonConnectUI.sendTransaction(tx);
      await tonConnectUI.sendTransaction(tx);
      console.log('Transaction successful');
    } catch (error) {
      console.error('Failed to send transaction:', error);
    }
  };

  

  const currentUrl =
    window.location.href.split("/")[window.location.href.split("/").length - 1];
  useEffect(() => {
    dispatch(
      setFooterState(currentUrl === undefined ? 1 : FooterUrl["/" + currentUrl])
    );
  }, []);
  useEffect(() => {
    dispatch(getUserProfile());
  }, [dispatch]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (userProfile?.telegram_id) {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/spin/${userProfile.telegram_id}`
          );
          const today = new Date();
          const dayOfWeek = today.getDay();
          const DailyData = [];
          // console.log(response.data);
          for (var i = 0; i < 7; i++) {
            DailyData.push({
              day: `Day ${i + 1}`,
              icon: response.data[i + ''] > 0 ? DailyIcon : ODailyIcon,
              checker: response.data[i + ''] > 0 ? CircleChecker : CircleChecker,
              amount: response.data[i + ''],
              passedDate: i <= dayOfWeek,
            })
          }
          //console.log(DailyData)
          setDailyData(DailyData);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [userProfile?.telegram_id]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (userProfile?.telegram_id) {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/user/${userProfile.telegram_id}/`
          );
          const tggAmountRaw = parseFloat(response.data.tgg_mount);
          setTon_mount((!isNaN(tggAmountRaw))
          ? tggAmountRaw.toFixed(2)
          : "0.00");
          setPoints(response.data.points);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [userProfile?.telegram_id]);


  // console.log( userProfile);
  const handleNavigate = (url) => {
    dispatch(
      setBackState(
        currentUrl == "" ||
        currentUrl == " " ||
        currentUrl == "undefined" ||
        currentUrl == null
          ? "/"
          : "/" + currentUrl
      )
    );
    dispatch(increaseIndex());
    navigate(url);
  };

  return (
    <React.Fragment>
      <Navbar/>
      <Box sx={{padding: "20px 20px 0 20px"}}>

      <Box sx={{padding: "8px 0px 0px"}}>
  <Box
    sx={{
      background: "rgba(247, 249, 251, 1)",
      borderRadius: "24px",
      padding: "8px",
      marginBottom: "10px",
    }}
  >
    {/* <Box sx={{ position: "relative", marginBottom: "58px", paddingTop: "66px" }}>
      <Box sx={{ position: "relative" }}>
        <Box
          className="absolute left-1/2 -translate-x-1/2 -translate-y-1/2"
          sx={{}}
        >
          <svg
            width="117"
            height="117"
            viewBox="0 0 117 117"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="58.5"
              cy="58.5"
              r="56"
              stroke="url(#paint0_linear_5_18894)"
              strokeWidth="5"
            />
            <defs>
              <linearGradient
                id="paint0_linear_5_18894"
                x1="5.48439"
                y1="74.9531"
                x2="52.0226"
                y2="-0.081653"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#0098EA" />
                <stop offset="0.861496" stopColor="#3940BE" />
              </linearGradient>
            </defs>
          </svg>
        </Box>
        <div
          className={`w-28 h-28 rounded-full border-4 border-white absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2`}
          style={{
            background: `url(${userProfile?.avatar}) lightgray 50% / cover no-repeat`,
          }}
        ></div>
      </Box>
    </Box> */}
    <div className="flex flex-row items-center justify-between"
      // sx={{
      //   display: "flex",
      //   alignItems: "center",
      //   justifyContent: "space-between",
      //   marginBottom: "30px",
      //   paddingLeft: "16px",
      // }}
    >
      <div className="flex flex-row items-center gap-2">
        <div
          style={{
            width: "40px",
            height: "40px",
            borderRadius: "50%",
            border: "2px solid white",
            background: `url(${userProfile?.avatar}) lightgray 50% / cover no-repeat`,
          }}
        ></div>
        <p
          style={{
            fontWeight: "600",
            fontSize: "16px",
            lineHeight: "24px",
          }}
        >
          {userProfile?.username || "--"}
        </p>
      </div>
      <TonConnectButton />
    </div>
  </Box>
</Box>

  



                     
        <Box
          className="cursor-pointer"
          sx={{
            position: "relative",
            borderRadius: "17.49px",
            background:
              "linear-gradient(0deg, rgba(14, 132, 196, 0.15), rgba(14, 132, 196, 0.15)), linear-gradient(82.05deg, #27A8ED 5.49%, #079AEB 48.11%, #53BEFE 96.19%)",
            padding: "23px 19px",
            marginBottom: "17px",
            overflow: "hidden",
          }}
          onClick={() => handleNavigate("/generate_refer_link")}
        >
          <Box
            sx={{
              height: "5px",
              width: "100%",
              borderBottomLeftRadius: "17.49px",
              borderBottomRightRadius: "17.49px",
              background: "rgba(14, 132, 196, 0.15)",
              position: "absolute",
              zIndex: 3,
              left: "0",
              bottom: "0",
            }}
          />
          <img
            style={{
              position: "absolute",
              width: "161px",
              bottom: "-23px",
              right: "-27px",
              zIndex: 2,
            }}
            src={Prize}
            alt="Prize"
          />
          <img
            style={{
              position: "absolute",
              bottom: "-15px",
              left: "-4px",
              opacity: 0.4,
              zIndex: 1,
            }}
            src={BSquare}
            alt="Square"
          />
          <img
            style={{
              position: "absolute",
              top: "-39px",
              left: "172px",
              opacity: 0.4,
              zIndex: 1,
            }}
            src={SSquare}
            alt="Square"
          />
          <img
            style={{
              position: "absolute",
              bottom: "-51px",
              left: "213px",
              opacity: 0.4,
              zIndex: 1,
            }}
            src={SSquare}
            alt="Square"
          />
          <p
            style={{
              fontWeight: "600",
              lineHeight: "16px",
              textAlign: "left",
              color: "white",
              marginBottom: "10px",
            }}
          >
            Refer your friends
          </p>
          <Box
            sx={{
              width: "48%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "space-between",
                width: "73%",
              }}
            >
              <img
                src={Man1}
                alt="Avatar"
                style={{zIndex: 4, width: "32px"}}
              />
              <img
                src={Man2}
                style={{marginLeft: "-10px", zIndex: 3, width: "32px"}}
                alt="Avatar"
              />
              <img
                src={Man3}
                style={{marginLeft: "-10px", zIndex: 2, width: "32px"}}
                alt="Avatar"
              />
              <img
                src={Man4}
                style={{marginLeft: "-10px", zIndex: 1, width: "32px"}}
                alt="Avatar"
              />
            </Box>
            <img src={GoOver} alt="Go Over Icon"/>
          </Box>
        </Box>
        
        <Box sx={{padding: "8px 0px 0px"}}>
          <Box
            sx={{
              background: "rgba(247, 249, 251, 1)",
              borderRadius: "24px",
              padding: "8px 17px 26px",
              marginBottom: "10px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "6px",
                marginBottom: "6px",
              }}
            >
              <img src={Gift} alt="Gift Icon"/>
              <h4 style={{color: "#252946", lineHeight: "20px"}}>
                My Won prize
              </h4>
            </Box>
            <p
              style={{
                fontSize: "11px",
                color: "rgba(37, 41, 70, 1)",
                marginBottom: "15px",
              }}
            >
              All the cash prizes and Toncoin prizes arestored in your
              blockchain wallet.
            </p>
            <div className="grid grid-cols-2 mb-6">
              {/* <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  justifyContent: "center",
                  borderRight: "1px solid rgba(186, 186, 188, 0.2)",
                  padding: "0 15px 0 15px",
                }}
              >
                <img
                  src={Money}
                  alt="Money"
                  style={{marginBottom: "6px", height: "40px"}}
                />
                <p
                  style={{
                    fontSize: "24px",
                    fontWeight: "600",
                    lineHeight: "24px",
                    color: "rgba(37, 41, 70, 1)",
                    marginBottom: "4px",
                  }}
                >
                  $1000
                </p>
                <p
                  style={{
                    color: "rgba(117, 117, 122, 1)",
                    lineHeight: "14px",
                    fontSize: "12px",
                  }}
                >
                  USD
                </p>
                <p
                  style={{
                    fontSize: "12px",
                    lineHeight: "14px",
                    color: "rgba(0, 152, 234, 1",
                    marginTop: "7px",
                    cursor: "pointer",
                  }}
                >
                  View
                </p>
              </Box> */}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  justifyContent: "center",
                  borderRight: "1px solid rgba(186, 186, 188, 0.2)",
                  padding: "0 15px 0 15px",
                }}
              >
                <img
                  src={Ton}
                  alt="Ton"
                  style={{marginBottom: "6px", height: "40px"}}
                />
                <p
                  style={{
                    fontSize: "24px",
                    fontWeight: "600",
                    lineHeight: "24px",
                    color: "rgba(37, 41, 70, 1)",
                    marginBottom: "4px",
                  }}
                >
                  {ton_mount}
                </p>
                <p
                  style={{
                    color: "rgba(117, 117, 122, 1)",
                    lineHeight: "14px",
                    fontSize: "12px",
                  }}
                >
                  TGG coin
                </p>
                {/* <p
                  style={{
                    fontSize: "12px",
                    lineHeight: "14px",
                    color: "rgba(0, 152, 234, 1",
                    marginTop: "7px",
                    cursor: "pointer",
                  }}
                >
                  View
                </p> */}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  justifyContent: "start",
                  // borderRight: "1px solid rgba(186, 186, 188, 0.2)",
                  padding: "0 15px 0 15px",
                }}
              >
                <img
                  src={Star}
                  alt="Money"
                  style={{marginBottom: "6px", height: "40px"}}
                />
                <p
                  style={{
                    fontSize: "24px",
                    fontWeight: "600",
                    lineHeight: "24px",
                    color: "rgba(37, 41, 70, 1)",
                    marginBottom: "4px",
                  }}
                >
                  {points?? 0}
                </p>
                <p
                  style={{
                    color: "rgba(117, 117, 122, 1)",
                    lineHeight: "14px",
                    fontSize: "12px",
                  }}
                >
                  Point
                </p>
              </Box>
            </div>
            {/* 
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "8px",
                marginBottom: "14px",
              }}
            >
              <Button
                className="poppines_font"
                startIcon={
                  <img
                    style={{width: "12px"}}
                    src={Deposit}
                    alt="Deposit Icon"
                  />
                }
                sx={{
                  flex: "1",
                  background:
                    "linear-gradient(87.02deg, #0098EA 19.69%, #32B6FD 93.81%)",
                  p: "8px 14px",
                  borderRadius: "40px",
                  fontWeight: "600",
                  fontSize: "12px",
                  color: "white !important",
                  width: "50%",
                  textTransform: "capitalize",
                }}
                onClick={() => handleNavigate("/deposit")}
              >
                Deposit
              </Button>
              <Button
                className="poppines_font"
                startIcon={
                  <img
                    style={{width: "12px"}}
                    src={Withdraw}
                    alt="Withdraw Icon"
                  />
                }
                sx={{
                  flex: "1",
                  background:
                    "linear-gradient(87.02deg, #748D99 19.69%, #93B1C0 93.81%)",
                  p: "8px 14px",
                  borderRadius: "40px",
                  fontWeight: "600",
                  fontSize: "12px",
                  color: "white !important",
                  letterSpacing: "-0.5px",
                  width: "50%",
                  textTransform: "capitalize",
                }}
              >
                Withdraw Prize
              </Button> 
            </Box>*/}
            <Box sx={{padding: "10px 0px 10px 0px"}}>
      
                <Box
            sx={{
              width: "100%",
              borderRadius: "20px",
              background: "rgba(0, 152, 234, 1)",
              cursor: "pointer",
              padding: "11px",
              margin:"auto",
              height: "40px",
            }}
            onClick={() => navigate('/deposit')}
          >
            <p
              style={{
                color: "white",
                fontSize: "12px",
              }}
            >
              Buy Points / $TGG
            </p>
          </Box>
                {/* <Button onClick={() => navigate('/deposit')} variant="contained" color="primary">
                  Buy Points/TGG
                </Button> */}
                
                {/* 展示钱包信息 */}
                {wallets.length > 0 && (
                  <Typography variant="body2">
                    {wallets.map((w, index) => (
                      <div key={index}>{w.address.slice(0, 6)}...{w.address.slice(-4)}</div>
                    ))}
                  </Typography>
                )}
             
          
            </Box>
            <Button
              className="poppines_font"
              startIcon={<img src={Redeem} alt="Icon"/>}
              sx={{
                border: "1px solid rgba(0, 152, 234, 1)",
                width: "100%",
                borderRadius: "40px",
                fontSize: "13px",
                fontWeight: "600",
                color: "rgba(0, 152, 234, 1)",
                letterSpacing: "-0.5px",
                marginBottom: "20px",
                textTransform: "none",
              }}
            >
              Redeem Points for Cash
            </Button>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "7px",
                alignItems: "center",
              }}
            >
              <p style={{fontSize: "11px", lineHeight: "14px"}}>
                Redeem Requirements:
              </p>
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    gap: "4px",
                  }}
                >
                  <img src={Checker} alt="Check Icon"/>
                  <p
                    style={{
                      fontSize: "10px",
                      lineHeight: "14px",
                      color: "rgba(117, 117, 122, 1)",
                      textAlign: "left",
                    }}
                  >
                    {"Balance > 10,000 Points or 100 TGG"}
                  </p>
                </Box>
              </Box>
            </Box>
          </Box>
          {/* <Box
            sx={{
              padding: "8px 12px 14px",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              gap: "14.5px",
              marginBottom: "10px",
              background: "rgba(247, 249, 251, 1)",
              borderRadius: "24px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "16px",
              }}
            >
              <img src={DChecker} alt="Icon"/>
              <p
                style={{
                  fontSize: "15px",
                  fontWeight: "600",
                  lineHeight: "24px",
                }}
              >
                Daily check-in
              </p>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: 0,
                marginBottom: "17px",
              }}
            >
              {dailyData.map((item, index) => (
                <DailyChecker
                  key={index}
                  day={item?.day}
                  icon={item?.icon}
                  checker={item?.checker}
                  amount={item?.amount}
                  selectedDate={item?.selectedDate}
                  passedDate={item?.passedDate}
                />
              ))}
            </Box>
            <Button
              className="poppines_font"
              sx={{
                flex: "1",
                background:
                  "linear-gradient(87.02deg, #0098EA 19.69%, #32B6FD 93.81%)",
                p: "8px 24px",
                borderRadius: "40px",
                fontWeight: "600",
                fontSize: "14px",
                color: "white !important",
                textTransform: "none",
              }}
            >
              Check In to Claim Point
            </Button>
          </Box> */}
          <DailyChallengesCard />
          {/* <TaskForYou /> */}
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default MyProfile;
