import React from 'react';
import Select, { components } from 'react-select';

const options = [
  { value: 'TON', label: 'TON', icon: <img src="https://cryptologos.cc/logos/toncoin-ton-logo.png" alt="TON Icon" style={{ width: '20px', marginRight: '5px' }} /> },
  { value: 'USDT', label: 'USDT (ERC-20)', icon: <img src="https://cryptologos.cc/logos/tether-usdt-logo.png" alt="USDT Icon" style={{ width: '20px', marginRight: '5px' }} /> },
  { value: 'USDTT', label: 'USDT (TRC-20)', icon: <img src="https://cryptologos.cc/logos/tether-usdt-logo.png" alt="USDT Icon" style={{ width: '20px', marginRight: '5px' }} /> },
  { value: 'BTC', label: 'BTC', icon: <img src="https://cryptologos.cc/logos/bitcoin-btc-logo.png" alt="BTC Icon" style={{ width: '20px', marginRight: '5px' }} /> },
  { value: 'ETH', label: 'ETH', icon: <img src="https://cryptologos.cc/logos/ethereum-eth-logo.png" alt="ETH Icon" style={{ width: '20px', marginRight: '5px' }} /> }
];

function CustomSelect() {
  const defaultOption = options[0]; // 获取第一个选项作为默认选项

  return (
    <Select
      options={options}
      defaultValue={defaultOption} // 设置默认选项为第一个选项的值
      styles={{
        control: provided => ({
          ...provided,
          width: '97%',
          borderRadius: '5px',
          border: '1px solid #ccc'
        }),
        option: (provided, state) => ({
          ...provided,
          display: 'flex',
          alignItems: 'center'
        }),
        placeholder: provided => ({ // 隐藏默认选项
          ...provided,
          display: 'none'
        })
      }}
      components={{
        Option: CustomOption
      }}
    />
  );
}



const CustomOption = ({ data, ...props }) => (
  <components.Option {...props}>
    {data.icon}
    {data.label}
  </components.Option>
);

export default CustomSelect;
