import React, {useEffect, useState} from "react";
import { Box, useTheme, Button } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { setBackState, increaseIndex } from "../features/navbarSlice";
import { getUserProfile } from "../middleware/userMiddleware";
import axios from "axios";

import Navbar from "../components/Navbar";
import RightArrow from "../assets/images/arrow_right.svg";
import UserProfileCard from "../components/UserProfileCard";
import ChallengeCard2 from "../assets/images/challenge_card_2.svg";
import ReferIcon from "../assets/images/refer_icon.png";
import { useNavigate } from "react-router-dom";
import MoneyIcon from "../assets/images/money_icon.svg";
import {setFooterHidden} from "../features/footerSlice";
import { toBase64 } from "../utils";
const QuertToEarn = () => {
  const dispatch = useDispatch();
  const userProfile = useSelector((state) => state.user.userProfile);
  const navigate = useNavigate();
  const [tasks, setTasks] = useState({
    is_joined_channel: true,
    is_joined_group: true,
    is_followed_twitter: true,
    invite_friends_completed: true,
  });
  const [loading, setLoading] = useState(false);
  const token = useSelector((state) => state.auth.access_token);
  const [referLink, setReferLink] = useState("");

  useEffect(() => {
    dispatch(setBackState("/"));
    dispatch(increaseIndex());
    dispatch(setFooterHidden(true))
  }, []);

  useEffect(() => {
    dispatch(getUserProfile());
  }, [dispatch]);

  useEffect(() => {
    if (!token) return;
    getTasks();
    getReferLink();
  }, [token]);

  const [telegramId, setTelegramId] = useState(
    userProfile?.telegram_id || 123123
  );

  async function getTasks() {
    if (loading) return;
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/users/${telegramId}/tasks`,
      );

      if (response?.data) {
        console.log('response?.data', response?.data);
        setTasks(response?.data);
      }
    } catch (e) {
      console.log(e?.response || e)
    } finally {
      setLoading(false);
    }
  }

  async function followedTwitter() {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/users/${telegramId}`,
        {
          is_followed_twitter: true,
        }
      );
      if (response?.data) {
        setTasks({ ...tasks, is_followed_twitter: true });
      }
    } catch (e) {
      console.log(e?.response || e)
    }
  }

  async function getReferLink() {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/user/${telegramId}/`,
      );

      if (response?.data?.referral) {
        setReferLink(
          `https://t.me/${process.env.REACT_APP_BOT_NAME}/${process.env.REACT_APP_BOT_MINIAPP}?startapp=${toBase64(JSON.stringify({
            referral: response.data.referral,
          }))}`
        );
      }
    } catch (e) {
      console.log(e?.response || e)
    }
  }

  const handleCopyLink = () => {
    if (!navigator.clipboard) {
      const textArea = document.createElement("textarea");
      textArea.value = referLink;
      textArea.style.position = "fixed";
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      document.execCommand("copy");
      document.body.removeChild(textArea);
      return;
    }

    navigator.clipboard.writeText(referLink).then(
      function() {
        console.log("copied");
      },
      function() {
        console.log("copy error");
      }
    );
  }

  const handleShareLink = () => {
    if (!referLink) {
      return;
    }
    let telegramLink = "https://t.me/share/url?url=";
    telegramLink += encodeURIComponent(referLink);
    window.location.href = telegramLink;
  }


  return (
    <Box sx={{ position: "relative",display:'flex',flexDirection:'column',height:'100vh' }}>
      <Navbar />
      {/* <UserProfileCard /> */}
      <Box sx={{ position: "relative", px: "21px", flex:1,background:`url(/Mask_group.png) no-repeat`,backgroundSize:'cover'}}>
        {/*<div className="flex flex-row items-center justify-between mb-4">*/}
        {/*  <p className="text-2xl font-black">*/}
        {/*    QUEST TO EARN*/}
        {/*  </p>*/}
        {/*  <div className={`${loading ? 'animate-spin' : ''}`} onClick={getTasks}>*/}
        {/*    <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" viewBox="0 0 24 24" fill="currentColor"><path d="M5.46257 4.43262C7.21556 2.91688 9.5007 2 12 2C17.5228 2 22 6.47715 22 12C22 14.1361 21.3302 16.1158 20.1892 17.7406L17 12H20C20 7.58172 16.4183 4 12 4C9.84982 4 7.89777 4.84827 6.46023 6.22842L5.46257 4.43262ZM18.5374 19.5674C16.7844 21.0831 14.4993 22 12 22C6.47715 22 2 17.5228 2 12C2 9.86386 2.66979 7.88416 3.8108 6.25944L7 12H4C4 16.4183 7.58172 20 12 20C14.1502 20 16.1022 19.1517 17.5398 17.7716L18.5374 19.5674Z"></path></svg>*/}
        {/*  </div>*/}
        {/*</div>*/}
        <Box
            className='w-[63px] flex pl-2 h-5 leading-5 text-left bg-[#FFEC83] absolute top-5 right-5 rounded-full text-xs'>
          {userProfile?.points ?? '--'}
          <img src="/fluent-emoji_ticket.png" alt="fluent-emoji_ticket" />
          <img src="/Frame_625079.png" alt="Frame" className='absolute -right-1 -top-1' />
        </Box>
        <Box className='flex mt-8'>
          <div className='uppercase text-white text-[40px] leading-[48px] w-[138px] h-[138px] font-extrabold' style={{textShadow:
            `-3px -3px 0 #FF9729,
            3px -3px 0 #FF9729,
            -3px 3px 0 #FF9729,
            3px 3px 0 #FF9729,
            -3px 0 0 #FF9729,
            3px 0 0 #FF9729,
            0 -3px 0 #FF9729,
            0 3px 0 #FF9729`,boxShadow: '0px 4px 4px 0px #00000008'
          }}>Quest to earn</div>
          <img src="/gift_box.png" alt="gift" width={167} height={178} />
        </Box>
        <Box sx={{background:'rgba(255, 255, 255, 0.5)'}} className='rounded-2xl p-2'>
          <Box className='rounded-2xl bg-[#FFE6DB] pt-5 px-4'>
            <div className="px-8 text-center text-xl mb-2">Collect <span className="text-[#FF5A50]">$TGG</span> to Earn
              Special Rewards!
            </div>
            <div className="flex flex-col gap-3">
              <div className="rounded-lg bg-[#f7f9fb] p-3 flex flex-col gap-6">
                <div className="flex flex-row items-center gap-2">
                  <img className="w-10 h-10" src={ReferIcon}/>
                  <div className="flex flex-col">
                    <div className="flex flex-row items-center text-xs">
                      Refer friends to visit mini app <span className="ml-2 text-base text-[#3F45B7]">+30</span>
                      <img className="ml-1 w-4 h-4" src={MoneyIcon}/>
                    </div>
                    <div className="text-xs">someone visits TG Games for the first time</div>
                  </div>
                </div>
                <div className="flex items-center justify-between rounded-md bg-white shadow-sm">
                  <input
                      className="border-0 outline-none bg-[#FFF4EE] w-full text-sm py-3"
                      value={referLink}
                      type="text"
                      placeholder="Generated Referred Link"
                  />
                  {/*<img src={RightArrow} alt="Right Arrow Icon"/>*/}
                </div>
                <div className="flex flex-row gap-4 items-center">
                  <Button
                      className="poppines_font"
                      sx={{
                        flex: "1",
                        background:
                            "linear-gradient(87.02deg, #FFD45A 19.69%, #FF4D62 93.81%)",
                        p: "8px 24px",
                        borderRadius: "40px",
                        fontWeight: "600",
                        fontSize: "14px",
                        color: "white !important",
                        textTransform: "none",
                        boxShadow: `0px 4px 4px 0px #FF71711A,0px 2px 8.2px 1px #FF5E5ED4 inset`
                      }}
                      // disabled={!duplicated && generated}
                      onClick={handleCopyLink}
                  >
                    Copy Link
                  </Button>
                  <Button
                      className="poppines_font"
                      sx={{
                        flex: "1",
                        background: "linear-gradient(87.02deg, #748D99 19.69%, #93B1C0 93.81%)",
                        p: "8px 24px",
                        borderRadius: "40px",
                        fontWeight: "600",
                        fontSize: "14px",
                        color: "white !important",
                        textTransform: "none",
                      }}
                      // disabled={!duplicated && generated}
                      onClick={handleShareLink}
                  >
                    Share Link
                  </Button>
                </div>
              </div>
              <div
                  className={`rounded-lg bg-[#f7f9fb] p-3 flex flex-row items-center gap-2 cursor-pointer ${tasks?.is_joined_channel ? "opacity-50" : ""}`}
                  onClick={() => {
                    if (tasks.is_joined_channel) {
                      return;
                    }

                    window.location.href = "https://t.me/TGGames_official"
                  }}>
                <div className="w-12 h-12 flex items-center justify-center rounded-full overflow-hidden">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-20 w-20 text-[#0098EA]" viewBox="0 0 24 24"
                       fill="currentColor">
                    <path
                        d="M12.001 22C6.47813 22 2.00098 17.5228 2.00098 12C2.00098 6.47715 6.47813 2 12.001 2C17.5238 2 22.001 6.47715 22.001 12C22.001 17.5228 17.5238 22 12.001 22ZM8.89113 13.1708L8.90378 13.1628C9.48351 15.0767 9.77337 16.0337 9.77337 16.0337C9.88564 16.3442 10.04 16.3996 10.2273 16.3743C10.4145 16.3489 10.5139 16.2476 10.6361 16.1297C10.6361 16.1297 11.0324 15.7472 11.825 14.9823L14.3759 16.8698C14.8407 17.1266 15.1763 16.9941 15.2917 16.4377L16.9495 8.61641C17.1325 7.88842 16.8115 7.59644 16.247 7.82754L6.51397 11.5871C5.84996 11.854 5.85317 12.2255 6.39308 12.3911L8.89113 13.1708Z"></path>
                  </svg>
                </div>
                <div className="flex-1 text-sm text-left">Follow TG Games Telegram Channel</div>
                <div className="text-sm font-base flex flex-row items-center">
                  30
                  <img className="ml-1 h-4" src={ChallengeCard2} alt="daily challenge card"/>
                </div>
              </div>
              <div
                  className={`rounded-2xl bg-[#f7f9fb] p-3 flex flex-row items-center gap-2 cursor-pointer ${tasks?.is_followed_twitter ? "opacity-50" : ""}`}
                  onClick={async () => {
                    if (tasks.is_followed_twitter) {
                      return;
                    }

                    await followedTwitter();
                    window.location.href = "https://twitter.com/TGGames_APP";
                  }}>
                <div className="w-10 h-10 m-1 flex items-center justify-center rounded-full bg-black">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-white" viewBox="0 0 24 24"
                       fill="currentColor">
                    <path
                        d="M18.2048 2.25H21.5128L14.2858 10.51L22.7878 21.75H16.1308L10.9168 14.933L4.95084 21.75H1.64084L9.37084 12.915L1.21484 2.25H8.04084L12.7538 8.481L18.2048 2.25ZM17.0438 19.77H18.8768L7.04484 4.126H5.07784L17.0438 19.77Z"></path>
                  </svg>
                </div>
                <div className="flex-1 text-sm text-left">Follow TG Games on X</div>
                <div className="text-sm font-base flex flex-row items-center">
                  30
                  <img className="ml-1 h-4" src={ChallengeCard2} alt="daily challenge card"/>
                </div>
              </div>
              <div
                  className={`rounded-2xl bg-[#f7f9fb] p-3 flex flex-row items-center gap-2 cursor-pointer ${tasks?.is_joined_group ? "opacity-50" : ""}`}
                  onClick={() => {
                    if (tasks.is_joined_group) {
                      return;
                    }

                    window.location.href = "https://t.me/TGG_Club";
                  }}>
                <div className="w-10 h-10 m-1 flex items-center justify-center rounded-full bg-yellow-500">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-white" viewBox="0 0 24 24"
                       fill="currentColor">
                    <path
                        d="M12 10C14.2091 10 16 8.20914 16 6 16 3.79086 14.2091 2 12 2 9.79086 2 8 3.79086 8 6 8 8.20914 9.79086 10 12 10ZM5.5 13C6.88071 13 8 11.8807 8 10.5 8 9.11929 6.88071 8 5.5 8 4.11929 8 3 9.11929 3 10.5 3 11.8807 4.11929 13 5.5 13ZM21 10.5C21 11.8807 19.8807 13 18.5 13 17.1193 13 16 11.8807 16 10.5 16 9.11929 17.1193 8 18.5 8 19.8807 8 21 9.11929 21 10.5ZM12 11C14.7614 11 17 13.2386 17 16V22H7V16C7 13.2386 9.23858 11 12 11ZM5 15.9999C5 15.307 5.10067 14.6376 5.28818 14.0056L5.11864 14.0204C3.36503 14.2104 2 15.6958 2 17.4999V21.9999H5V15.9999ZM22 21.9999V17.4999C22 15.6378 20.5459 14.1153 18.7118 14.0056 18.8993 14.6376 19 15.307 19 15.9999V21.9999H22Z"></path>
                  </svg>
                </div>
                <div className="flex-1 text-sm text-left">Join TGG Club Telegram Group</div>
                <div className="text-sm font-base flex flex-row items-center">
                  30
                  <img className="ml-1 h-4" src={ChallengeCard2} alt="daily challenge card"/>
                </div>
              </div>
            </div>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default QuertToEarn;
