// middleware/userMiddleware.js
import axios from "axios";
import { setAuthToken, setAccessToken } from "../features/authSlice";
import { setUserProfile } from "../features/userSlice";
import { parseJwt } from "../utils";

export const getAuthToken = (referral) => async (dispatch) => {
  try {
    const initData = window?.Telegram?.WebApp?.initData || process.env.REACT_APP_TEST_INITDATA;
    const payload = new FormData();
    payload.append("initData", initData);

    if (referral) {
      payload.append("referral", referral);
    }

    const response = await axios.post(`${process.env.REACT_APP_API_URL}/tokens`, payload);

    dispatch(setAccessToken(response.data));
    dispatch(setUserProfile(parseJwt(response.data.token)?.user));
  } catch (error) {
    console.error("Error fetching token:", error);
  }
};

const authMiddleware = (store) => (next) => (action) => {
  next(action);
};

export default authMiddleware;
