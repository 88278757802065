import React, { useMemo, useEffect } from 'react'
import { createTheme } from '@mui/material/styles'
import { Routes, Route, useLocation } from 'react-router-dom'
import { ThemeProvider } from '@mui/material/styles'
import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import Home from './scenes/home'
import AnswerSurvey from './scenes/AnswerSurvey'
import ShopEarn from './scenes/ShopEarn'
import Tournaments from './scenes/Tournaments'
import TournamentsDetail from './scenes/TournamentsDetail'
import NFTExplain from './scenes/NFTExplain'
import TGExplain from './scenes/TGExplain'
import Game from './scenes/Game'
import MyProfile from './scenes/MyProfile'
import EditProfile from './scenes/EditProfile'
import Levels from './scenes/Levels'
import Deposit from './scenes/Deposit'
import GenerateReferLink from './scenes/GenerateReferLink'
import QuestToEarn from './scenes/QuestToEarn'
import DailySpinLink from './scenes/DailySpinLink'
import TonEcoDetail from './scenes/TonEcoDetail'
import { getAuthToken, checkRefreshToken } from './middleware/authMiddleware'
import Footer from './components/Footer'

import themeSettings from './theme'
import { useDispatch, useSelector } from 'react-redux'
import { parseQueryString } from './utils'
import { TonConnectUIProvider } from '@tonconnect/ui-react'
import { register } from 'swiper/element/bundle'
import './App.css'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
// register Swiper custom elements
register()
dayjs.extend(utc)

const queryClient = new QueryClient()

const App = () => {
  const dispatch = useDispatch()
  const { isAuthrized } = useSelector(({ auth }) => auth)
  const footerHidden = useSelector((state) => state.footerState.hidden)

  const theme = useMemo(() => createTheme(themeSettings))
  const location = useLocation()

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname])

  useEffect(() => {
    if (!isAuthrized) {
      dispatch(
        getAuthToken(parseQueryString(location.search)?.tgWebAppStartParam)
      )
    }
  }, [dispatch, isAuthrized])

  return (
    <TonConnectUIProvider
      manifestUrl={`${process.env.REACT_APP_BOT_MINIAPP_URL}/tonconnect-manifest.json`}
      restoreConnection={true}
    >
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <div
            className={`h-screen w-screen overflow-y-scroll ${
              footerHidden ? '' : 'pb-20'
            }`}
          >
            <Routes>
              <Route exact path="/" element={<Home />} />
              <Route exact path="/answer_survey" element={<AnswerSurvey />} />
              <Route exact path="/tournaments" element={<Tournaments />} />
              <Route
                exact
                path="/tournaments_detail"
                element={<TournamentsDetail />}
              />
              <Route exact path="/nft_explain" element={<NFTExplain />} />
              <Route exact path="/telegram_explain" element={<TGExplain />} />
              <Route exact path="/my_profile" element={<MyProfile />} />
              <Route exact path="/edit_profile" element={<EditProfile />} />
              <Route exact path="/levels" element={<Levels />} />
              <Route exact path="/deposit" element={<Deposit />} />
              <Route exact path="/eco" element={<TonEcoDetail />} />
              <Route exact path="/game" element={<Game />} />
              <Route
                exact
                path="/generate_refer_link"
                element={<GenerateReferLink />}
              />
              <Route exact path="/quest_to_earn" element={<QuestToEarn />} />
              <Route
                exact
                path="/daily_spin"
                element={<DailySpinLink />}
              />{' '}
              <Route
                exact
                path="/shop_earn_deals"
                element={<ShopEarn type="deals" />}
              />
              <Route
                exact
                path="/shop_earn_gift"
                element={<ShopEarn type="gift" />}
              />
            </Routes>
          </div>
          <div className=" w-full max-w-full bg-white fixed bottom-0 left-0 z-50">
            <div className="rounded-tl-sm rounded-tr-sm">
              <Footer />
            </div>
          </div>
        </ThemeProvider>
      </QueryClientProvider>
    </TonConnectUIProvider>
  )
}

export default App
