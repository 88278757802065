import {
  Box,
  useTheme,
  Button,
  Typography,
  Alert,
  Drawer,
  TextField,
  Grid,
  CircularProgress,
} from "@mui/material";
import { useSelector } from "react-redux"
import MoneyCard from "./MoneyCard";
import ProfileIcon from "../assets/images/profile.png";
import MoneyIcon from "../assets/images/money_icon.svg";
import MoneyCardIcon from "../assets/images/money_card_icon.svg";
import { useNavigate } from "react-router-dom";

export default function UserProfileCard() {
  const userProfile = useSelector(store => store.user.userProfile);
  const theme = useTheme();
  const navigate = useNavigate();

  const handleClickUsd = () => {
    navigate('/deposit?usdt=1000')
  }

  const handleClickPoint = () => {
    navigate('/');
  }

  return <Box
  className=" w-full px-main"
    sx={{
      position: "relative",
      textAlign: "center",
      margin: `${theme.gaps[1]} 0`,
      marginTop: "14px",
    }}
  >
    <Box

      sx={{
        display: "flex",
        background: theme.bgColor[0],
        alignItems: "center",
        justifyContent: "space-between",
        borderRadius: "24px",
        padding: "9px 9px 12px 10px",
      }}
    >
      <div className="flex flex-row items-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="50"
          height="50"
          viewBox="0 0 50 50"
          fill="none"
        >
          <circle
            cx="25"
            cy="25"
            r="23.4375"
            stroke="url(#paint0_linear_640_3608)"
            strokeWidth="3.125"
          />
          <defs>
            <linearGradient
              id="paint0_linear_640_3608"
              x1="2.34376"
              y1="32.0313"
              x2="22.2319"
              y2="-0.0348944"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#0098EA" />
              <stop offset="0.861496" stopColor="#3940BE" />
            </linearGradient>
          </defs>
        </svg>
        <Box
          style={{
            position: "absolute",
            width: "43.75px",
            height: "43.75px",
            flexShrink: 0,
            top: "12px",
            left: "34px",
            background: `url(${
              userProfile?.avatar || ProfileIcon
            }) lightgray 50% / cover no-repeat`,
            borderRadius: "50%",
          }}
        />
        <Box sx={{ marginLeft: "12px" }}>
          <p
            className="poppines_font profile_name"
            style={{
              fontSize: "15px",
              fontWeight: theme.fontWeight.bold,
              lineHeight: "22.5px",
              textAlign: "left",
            }}
          >
            {userProfile?.username || "--"}
          </p>
        </Box>
      </div>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <MoneyCard
          amount={(!isNaN(parseFloat(userProfile?.tgg_mount)))
            ? parseFloat(userProfile?.tgg_mount).toFixed(2)
            : "--"}
          icon={MoneyIcon}
          bgColor="#CBCDFF"
          color="#414646"
          iconColor="linear-gradient(199.44deg, #8489E7 16.3%, #3F45B7 86.96%)"
          onClick={handleClickUsd}
        />

        <MoneyCard
          amount={userProfile?.points ?? '--'}
          icon={MoneyCardIcon}
          bgColor="#FFEC83"
          color="#542700"
          iconColor="linear-gradient(199.44deg, #FFD612 16.3%, #F99C39 86.96%)"
          onClick={handleClickPoint}
        />
      </Box>
    </Box>
  </Box>
}