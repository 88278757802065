import React, { useState, useEffect } from 'react';

const CountdownTimer = ({ seconds }) => {
  const [timeLeft, setTimeLeft] = useState(seconds);

  useEffect(() => {
    const countdown = setInterval(() => {
      setTimeLeft(prevTime => {
        if (prevTime === 0) {
          clearInterval(countdown);
          // 在倒计时结束时执行其他操作，比如显示提示信息
          console.log('倒计时结束！');
          return 0;
        } else {
          return prevTime - 1;
        }
      });
    }, 1000);

    return () => clearInterval(countdown);
  }, []);

  const days = Math.floor(timeLeft / (3600 * 24));
  const hours = Math.floor((timeLeft % (3600 * 24)) / 3600);
  const minutes = Math.floor((timeLeft % 3600) / 60);
  const remainingSeconds = timeLeft % 60;

  return (
    <div className="flex flex-row w-full px-4 divide-x">
      <div className='flex-1 flex flex-col items-center space-y-1'>
        <p className='text-center'>{days}</p>
        <p className='text-xs text-center'>Days</p>
      </div>
      <div className='flex-1 flex flex-col items-center space-y-1'>
        <p className='text-center'>{hours}</p>
        <p className='text-xs text-center'>Hours</p>
      </div>
      <div className='flex-1 flex flex-col items-center space-y-1'>
        <p className='text-center'>{minutes}</p>
        <p className='text-xs text-center'>Minutes</p>
      </div>
      <div className='flex-1 flex flex-col items-center space-y-1'>
        <p className='text-center'>{remainingSeconds}</p>
        <p className='text-xs text-center'>Seonds</p>
      </div>
    </div>
  );
};

export default CountdownTimer;