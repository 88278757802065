import { Box, Button } from "@mui/material";
import DailyChallenge from "./DailyChallenge";
import BDailyChallengeCard from "../assets/images/b_daily_challenge_card_1.svg";
import ChallengeCard2 from "../assets/images/challenge_card_2.svg";
import Cup from "../assets/images/cup.svg";
import { DailyChallengeData } from "../Constant";
import { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";

export default function DailyChallengesCard() {
  const [data, setData] = useState([
    {
      id: 1,
      points: 5,
      checked: false,
      is_today: false,
    },
    {
      id: 2,
      points: 5,
      checked: false,
      is_today: false,
    },
    {
      id: 3,
      points: 5,
      checked: false,
      is_today: false,
    },
    {
      id: 4,
      points: 5,
      checked: false,
      is_today: false,
    },
    {
      id: 5,
      points: 5,
      checked: false,
      is_today: false,
    },
    {
      id: 6,
      points: 5,
      checked: false,
      is_today: false,
    },
    {
      id: 7,
      points: 20,
      checked: false,
      is_today: false,
    },
  ]);
  const accessToken = useSelector(state => state.auth.access_token);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (accessToken) {
      axios.get(`${process.env.REACT_APP_API_URL}/challenges`)
      .then(response => {
        setData(response.data)
      }).catch((err) => console.log(err))
    }
  }, [accessToken]);

  const handleClaim = (day) => {
    if (loading) {
      return;
    }
    
    setLoading(true);
    axios.post(`${process.env.REACT_APP_API_URL}/challenges`)
      .then(response => {
        setData(response.data)
      })
      .catch(err => console.log(err))
      .finally(() => setLoading(false));
  }

  const lastDay = data[6];

  return (
    <Box
      sx={{
        padding: "8px 18px 13px",
        background: "rgba(247, 249, 251, 1)",
        borderRadius: "24px",
        marginBottom: "24px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "8px",
          marginBottom: "10px",
        }}
      >
        <img src={Cup} alt="Cup Icon"/>
        <p
          style={{
            fontSize: "15px",
            fontWeight: "600",
            lineHeight: "24px",
          }}
        >
          Daily challenges
        </p>
      </Box>
      <p
        style={{
          fontSize: "15px",
          fontWeight: "600",
          lineHeight: "24px",
          marginBottom: "18px",
        }}
      >
        Win 1 match every day to claim
      </p>
      <Box>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns:
              "minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr)",
            gap: "10px",
            marginBottom: "8px",
          }}
        >
          {data.slice(0, 6).map((item, index) => (
            <DailyChallenge
              key={index}
              day={item?.id}
              amount={item?.points}
              selectedDate={item?.is_today}
              passedChallenge={item?.checked}
              loading={loading}
              onClaim={handleClaim}
            />
          ))}
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            background: "white",
            boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.03)",
            borderRadius: "7px",
            padding: "3.78px 17px 5.67px 11.33px",
            marginBottom: "8px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              gap: "8px",
              width: "100%",
            }}
          >
            <img className="h-6" src={ChallengeCard2} alt="daily challenge card"/>
            <Box>
              <p
                style={{
                  fontSize: "11px",
                  lineHeight: "14px",
                  color: "rgba(117, 117, 122, 1)",
                  marginBottom: "4px",
                  textAlign: "left",
                }}
              >
                Day 7
              </p>
              <p
                style={{
                  fontSize: "17px",
                  lineHeight: "24px",
                  fontWeight: "600",
                  color: "rgba(37, 41, 70, 1)",
                  textAlign: "left",
                }}
              >
                {lastDay.points} Points
              </p>
            </Box>
          </Box>
          <Button
            variant="outlined"
            style={{
              // padding: "3.78px 11.33px 5.67px 11.33px",
              fontSize: "8px",
              lineHeight: "9.44px",
              borderRadius: "18.89px",
              border: "0.5px solid rgba(215, 215, 215, 1)",
              color: "rgba(176, 176, 176, 1)",
            }}
            disabled={!lastDay.selectedDate || loading}
          >
            Claim
          </Button>
        </Box>
        <p
          style={{
            fontSize: "9px",
            lineHeight: "14px",
            color: "rgba(117, 117, 122, 1)",
            textAlign: "left",
          }}
        >
          Ensure a daily win and don't exceed 24 hours between wins to
          avoid resetting the record.
        </p>
      </Box>
    </Box>
  )
}