import React, { useEffect, useState } from "react";
import { Box, useTheme, Button } from "@mui/material";

import Navbar from "../components/Navbar";
import DepositCard from "../components/DepositCard";
import CustomSelect from '../components/CustomSelect';
import DepositCurrencyCard from "../components/DepositCurrencyCard"; // Added import

import GameController from "../assets/images/game_controller.svg";
import TCoin from "../assets/images/T_coin.svg";
import DepositIcon from "../assets/images/deposit.svg";
import DropDownIcon from "../assets/images/dropdown_icon.svg";
import BtcIcon from "../assets/images/btc.svg";
import EthIcon from "../assets/images/eth.svg";
import PointsIcon from "../assets/images/tg_coin_card.png";
import TonIcon from "../assets/images/toncoin.svg"; // Added import
import TetherCoin from "../assets/images/Tether_coin.png"; // Added import
import EtherCoin from "../assets/images/Ether_coin.png"; // Added import
import RightArrow from "../assets/images/arrow_right.svg"; // Added import
import CloseIcon from "../assets/images/close.svg"; // Added import
import { useTonConnectModal, useTonWallet } from "@tonconnect/ui-react";
import { useTonAddress, useTonConnectUI, TonConnectButton } from "@tonconnect/ui-react";

import axios from "axios";
import QRCode from 'qrcode.react';
import { useSelector } from "react-redux";
import { RiFileCopy2Line } from 'react-icons/ri';

const Deposit = () => {
  const userFriendlyAddress = useTonAddress();
  const rawAddress = useTonAddress(false);
  const wallet = useTonWallet();
  const { state, open, close } = useTonConnectModal();

  const [tonConnectUI, setOptions] = useTonConnectUI();

  const theme = useTheme();

  const [selectedButton, setSelectedButton] = React.useState("Points");
  const [rateTG2USD, setRateTG2USD] = React.useState(1.1);
  const [rateP2TON, setP2TON] = React.useState(200);
  const [rateT2TON, setT2TON] = React.useState(2);
  const [rateETH2USD, setRateETH2USD] = React.useState(2757);
  const [depositAmount, setDepositAmount] = React.useState(1);
  const [buyPoints, setBuyPoints] = React.useState();
  const [buyTGG, setBuyTGG] = React.useState();
  const [walletAddress, setWalletAddress] = React.useState("");
  const [editCurrency, setEditCurrency] = React.useState(false);
  const [currency, setCurrency] = React.useState('btc');
  const userProfile = useSelector(store => store.user.userProfile);
  const address = useTonAddress();
  const [balance, setBalance] = useState(null);
  const [pointAmount, setPointAmount] = useState(0);
  const [TGGAmount, setTGGAmount] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');


  const handleChangePoints = (e) => {
    const value = e.target.value;
    // 仅允许正整数，最小值为1
    if (/^\d*$/.test(value) && (parseInt(value, 10) >= 1 || value === '')) {
      setBuyPoints(value);
    }
  };

  const handleChangeTGG = (e) => {
    const value = e.target.value;
    // 仅允许正整数，最小值为1
    if (/^\d*$/.test(value) && (parseInt(value, 10) >= 1 || value === '')) {
      setBuyTGG(value);
    }
  };
  

  useEffect(() => {
    const points = parseInt(buyPoints, 10);
    if (!isNaN(points)) {
      if (points >= 100) {
        setPointAmount(points / rateP2TON);
        setErrorMessage(''); // Clear any error message
      } else {
        setErrorMessage('The minimum purchase amount is 100 Points'); // Set error message
        setPointAmount(0); // Optionally reset pointAmount
      }
    } else {
      setPointAmount(0); // Reset pointAmount if input is not a number
      setErrorMessage(''); // Clear error message if input is cleared
    }
  }, [buyPoints]);

  useEffect(() => {
    const tggs = parseInt(buyTGG, 10);
    if (!isNaN(tggs) && tggs > 0) {
      setTGGAmount(tggs / rateT2TON);
    } else {
      setTGGAmount(0);
    }
  }, [buyTGG]);


  useEffect(() => {
    if (address) {
      fetchBalance(address);
    }
  }, [address]);

  const fetchBalance = async (address) => {
    try {
      const balanceUrl = `https://toncenter.com/api/v2/getAddressBalance?address=${address}`;
      const response = await axios.get(balanceUrl);
      setBalance(response.data.result / 1000000000); // 转换为 TON 单位
    } catch (error) {
      console.error('Error fetching balance:', error);
    }
  };


  const handleSetSelectedButton = (button) => {
    setSelectedButton(button);
    if (!wallet?.name) {
      open();
    }
  };

  useEffect(() => {
    setWalletAddress(userFriendlyAddress);
  }, [userFriendlyAddress]);

  const [walletAddress1, setWalletAddress1] = useState(userProfile?.eth);

  const handleTransactionPoints = async () => {
    if (!wallet?.name) {
      open();
      return;
    }
    if (!buyPoints) {
      return;
    }
    if (!(buyPoints>0)) {
      return;
    }

    const myTransaction = {
      validUntil: Math.floor(Date.now() / 1000) + 600,
      messages: [
        {
          address: "UQCrzFGbdBPtndRV35fDyQNkvKKeuK9nrtL2Wj872aJaJFvP",
          amount: (Math.floor(pointAmount * 100000000)).toString(),
        },
      ],
    };

    try {
      //console.log(Math.floor(pointAmount * 1000000));
      
      const result = await tonConnectUI.sendTransaction(myTransaction, {
        modals: ["before", "success", "error"],
        notifications: ["before", "success", "error"],
      });
      // console.log({ myTransaction, result });
      //await tonConnectUI.sendTransaction(myTransaction);
      boughtPoints();
    } catch (error) {
      console.error(error);
    }
  };

  const handleTransactionTGG = async () => {
    if (!wallet?.name) {
      open();
      return;
    }
    if (!buyTGG) {
      return;
    }
    if (!(buyTGG>0)) {
      return;
    }

    const myTransaction2 = {
      validUntil: Math.floor(Date.now() / 1000) + 600,
      messages: [
        {
          address: "UQCrzFGbdBPtndRV35fDyQNkvKKeuK9nrtL2Wj872aJaJFvP",
          amount: (Math.floor(TGGAmount * 100000000)).toString(),
        },
      ],
    };

    try {
      //console.log(Math.floor(TGGAmount * 100000000));
      const result = await tonConnectUI.sendTransaction(myTransaction2, {
        modals: ["before", "success", "error"],
        notifications: ["before", "success", "error"],
      });
      // console.log({ myTransaction, result });
      boughtTGG();
    } catch (error) {
      console.error(error);
    }
  };


  const handleCopy = () => {
    navigator.clipboard.writeText(walletAddress1);
    alert('Wallet address copied to clipboard!');
  };

  const handleRakutenAPI = async () => {
    const result = await axios.get("https://api.linksynergy.com/coupon/1.0");
  };

  const [loading, setLoading] = React.useState(false);
  const [amount, setAmount] = React.useState('');

  const handleAmountChange = (event) => {
    setAmount(event.target.value);
  };

  async function boughtPoints() {
    try {
      console.log(userProfile.telegram_id);
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/users/${userProfile.telegram_id}/buypoints`,
        {
          pointsAmount: String(buyPoints),
        }
      );
      if (response?.data) {
        console.log(response.data);
      }
    } catch (e) {
      console.log(e?.response || e)
    }
  }

  async function boughtTGG() {
    try {
      console.log(userProfile.telegram_id);
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/users/${userProfile.telegram_id}/buytggs`,
        {
          TGGAmount: String(buyTGG),
        }
      );
      if (response?.data) {
        console.log(response.data);
      }
    } catch (e) {
      console.log(e?.response || e)
    }
  }

  async function handleDepositExpress(){
    const data = {
      amount: 0.1,
      telegramid: userProfile.telegram_id,
    };
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/wallet/handleDepositExpress`, data
    );
    const payLink = response.data.data.payLink;
    const directPayLink = response.data.data.directPayLink;
    window.Telegram.WebApp.openTelegramLink(directPayLink);
    return response.data.data;
  }

  return (
    <Box sx={{ position: "relative" }}>
      <Navbar />
      <Box sx={{ px: "21px", height: "100vh" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "15px",
          }}
        >
          <img src={GameController} alt="Icon" />
          <Box
            sx={{
              borderRadius: "100px",
              padding: "4px 6px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              background: "rgba(241, 241, 242, 1)",
            }}
          >
           <TonConnectButton style={{ margin: 'auto'}} /> {/* 显示连接钱包按钮 */}
            <Box
              sx={{
                borderRadius: "20px",
                padding: "4px 16px",
                display: "flex",
                alignItems: "center",
                gap: "5px",
              }}
            >
              <img src={TonIcon} alt="Coin Icon" style={{width:'29px'}}/>
              <p
                style={{
                  color: "#2F2F2F",
                  fontSize: "12.37px",
                }}
              >
                {balance ? balance : "-"}
              </p>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            borderRadius: "100px",
            padding: "4px 6px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: "rgba(241, 241, 242, 1)",
            marginBottom: "15px",
          }}
        >
          <Box
            sx={{
              width: "50%",
              borderRadius: "20px",
              background:
                selectedButton === "Points" ? "rgba(0, 152, 234, 1)" : "unset",
              cursor: "pointer",
              padding: "4px",
            }}
            onClick={() => handleSetSelectedButton("Points")}
          >
            <p
              style={{
                color:
                  selectedButton === "Points"
                    ? "white"
                    : "rgba(117, 117, 122, 1)",
                fontSize: "12px",
              }}
            >
              Points
            </p>
          </Box>
          <Box
            sx={{
              width: "50%",
              borderRadius: "20px",
              background:
                selectedButton === "TGG"
                  ? "rgba(0, 152, 234, 1)"
                  : "unset",
              cursor: "pointer",
              padding: "4px",
            }}
            onClick={() => handleSetSelectedButton("TGG")}
          >
            <p
              style={{
                color:
                  selectedButton === "TGG"
                    ? "white"
                    : "rgba(117, 117, 122, 1)",
                fontSize: "12px",
              }}
            >
              TGG
            </p>
          </Box>
        </Box>

        {selectedButton === "Points" ? (
          <Box
            sx={{
              backgroundColor: "#F7F9FB",
              pt: "16px",
              pb: "16px",
              overflow: "hidden",
              borderRadius: "24px",
            }}
          >
            <div>
              <div style={{
                color: "rgba(117, 117, 122, 1)",
                fontSize: "16px",
                paddingLeft: "10px",
                textAlign: 'left'
              }}>Purchase Points
              </div>

              
              <div style={{ width: '100%', position: 'relative', padding: '10px' }}>
              <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                background: "rgba(169, 169, 169, 0.1)",
                px: "15px",
                borderRadius: "20px",
              }}
            >
              <input
                style={{
                  border: "0",
                  background: "rgba(169, 169, 169, 0)",
                  outline: "none",
                  width: "100%",
                  height: "44px",
                }}
                value={buyPoints}
                onChange={handleChangePoints}
                placeholder="Points"
                type="number"
                inputMode="numeric" // 确保在所有设备上显示数字键盘
                pattern="[0-9]*" // 限制输入内容为数字
              />
              <img src={PointsIcon} alt="Points Icon" style={{width:'23px'}}/>
            </Box>
            {errorMessage && (
                <Box
                  sx={{
                    color: 'red',
                    padding: '10px',
                    backgroundColor: '#FFD2D2', // Light red background for emphasis
                    borderRadius: '5px',
                    margin: '10px 0',
                  }}
                >
                  {errorMessage}
                </Box>
              )}
              </div>
              <div style={{ width: '100%', position: 'relative', padding: '10px' }}>
              <Box sx={{ marginBottom: "2px" }}>
              <DepositCurrencyCard
                type="TG"
                bgColor="linear-gradient(82deg, #0098EA 5.49%, #0098EA 48.11%, #53BEFE 96.19%)"
                icon={PointsIcon}
                coinName={"Points from TON"}
                currency={""}
                amount={pointAmount.toPrecision(6)}
                onClick={handleTransactionPoints}
              />
            </Box>
              </div>

            
       
              <button onClick={handleTransactionPoints} disabled={loading} style={{
                background: 'linear-gradient(90deg, #0098EA 0%, #32B6FD 100%)',
                border: 'none',
                borderRadius: '40px',
                color: '#fff',
                padding: '10px 20px',
                margin: '10px',
                cursor: 'pointer',
                minWidth: '160px',
              }}>
                Buy Now
              </button>
             
              <div style={{ borderTop: '1px solid #eee', margin: '4px 10px' }} />

            </div>

            <p
              style={{
                fontWeight: "700",
                fontSize: "11px",
                lineHeight: "14px",
                color: "0E0E0E",
              }}
            >
              1 TON = {rateP2TON} Points
            </p>
          </Box>
        ) : (
          <>
             <Box
            sx={{
              backgroundColor: "#F7F9FB",
              pt: "16px",
              pb: "16px",
              overflow: "hidden",
              borderRadius: "24px",
            }}
          >
            <div>
              <div style={{
                color: "rgba(117, 117, 122, 1)",
                fontSize: "16px",
                paddingLeft: "10px",
                textAlign: 'left'
              }}>Purchase TGG
              </div>

              
              <div style={{ width: '100%', position: 'relative', padding: '10px' }}>
              <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                background: "rgba(169, 169, 169, 0.1)",
                px: "15px",
                borderRadius: "20px",
              }}
            >
              <input
                style={{
                  border: "0",
                  background: "rgba(169, 169, 169, 0)",
                  outline: "none",
                  width: "100%",
                  height: "44px",
                }}
                value={buyTGG}
                onChange={handleChangeTGG}
                placeholder="TGG"
                type="number"
                inputMode="numeric" // 确保在所有设备上显示数字键盘
                pattern="[0-9]*" // 限制输入内容为数字
              />
              <img src={TCoin} alt="TGG Icon" style={{width:'23px'}}/>
            </Box>
              </div>
              <div style={{ width: '100%', position: 'relative', padding: '10px' }}>
              <Box sx={{ marginBottom: "2px" }}>
              <DepositCurrencyCard
                type="TG"
                bgColor="linear-gradient(82deg, #0098EA 5.49%, #0098EA 48.11%, #53BEFE 96.19%)"
                icon={TCoin}
                coinName={"TGG from TON"}
                currency={""}
                amount={TGGAmount.toPrecision(6)}
                onClick={handleTransactionTGG}
              />
            </Box>
              </div>

            
       
              <button onClick={handleTransactionTGG} disabled={loading} style={{
                background: 'linear-gradient(90deg, #0098EA 0%, #32B6FD 100%)',
                border: 'none',
                borderRadius: '40px',
                color: '#fff',
                padding: '10px 20px',
                margin: '10px',
                cursor: 'pointer',
                minWidth: '160px',
              }}>
                Buy Now
              </button>
             
              <div style={{ borderTop: '1px solid #eee', margin: '4px 10px' }} />

            </div>

            <p
              style={{
                fontWeight: "700",
                fontSize: "11px",
                lineHeight: "14px",
                color: "0E0E0E",
              }}
            >
              1 TON = {rateT2TON} TGG
            </p>
          </Box>
          </>
        )}
        <Box
          sx={{
            position: "absolute",
            width: "100%",
            height: "100vh",
            background: "rgba(0, 0, 0, 0.44)",
            top: "0",
            left: "0",
            alignItems: "flex-end",
            justifyContent: "center",
            display: editCurrency ? "flex" : "none",
            transition: "all 0.4s",
          }}
        >
          <Box
            sx={{
              padding: "12px 21px 39px",
              position: "relative",
              background: "white",
              width: "100%",
              borderTopRightRadius: "10px",
              borderTopLeftRadius: "10px",
            }}
          >
            <img
              style={{
                position: "absolute",
                top: "12px",
                right: "16px",
                cursor: "pointer",
              }}
              src={CloseIcon}
              alt="Close Icon"
              onClick={() => setEditCurrency(false)}
            />
            <Box sx={{ marginBottom: "29px" }}>
              <p
                style={{
                  fontSize: "15px",
                  fontWeight: "600",
                  lineHeight: "24px",
                  color: "#252946",
                }}
              >
                Deposit
              </p>
            </Box>
            <Box sx={{ marginBottom: "22px", textAlign: "left" }}>
              <p
                style={{
                  fontSize: "13px",
                  fontWeight: "15px",
                  color: "#252946",
                  lineHeight: "24px",
                }}
              >
                Choose your preferred currency
              </p>
            </Box>
            <Box sx={{ marginBottom: "29px" }}>
              <DepositCurrencyCard
                type="TG"
                bgColor="linear-gradient(82deg, #0098EA 5.49%, #0098EA 48.11%, #53BEFE 96.19%)"
                icon={TCoin}
                coinName={"TG coin"}
                currency={"TG"}
                amount={depositAmount.toPrecision(3)}
                onClick={async () => {
                  if (!wallet?.name) {
                    open();
                  }

                  const myTransaction = {
                    validUntil: Math.floor(Date.now() / 1000) + 360,
                    messages: [
                      {
                        address:
                          "UQDuOlIqYLpC55bsjQwfO6pL70AqYlSR8W-SdtNMkeqI3mSX",
                        amount: (depositAmount * 1000000).toString(),
                      },
                    ],
                  };

                  tonConnectUI
                    .sendTransaction(myTransaction, {
                      modals: ["before", "success", "error"],
                      notifications: ["before", "success", "error"],
                    })
                    .then((result) => {
                      console.log({ myTransaction, result });
                    })
                    .catch((error) => {
                      console.error(error);
                    });
                }}
              />
            </Box>
            <Box sx={{ marginBottom: "16px" }}>
              <DepositCurrencyCard
                type="Tether"
                bgColor="linear-gradient(82deg, #DEDEDE 5.49%, #E6E6E6 48.11%, #EAEAEA 96.19%)"
                icon={TetherCoin}
                coinName={"Tether"}
                currency={"USDT"}
                amount={(depositAmount * rateTG2USD).toPrecision(3)}
              />
            </Box>
            <Box sx={{ marginBottom: "29px" }}>
              <DepositCurrencyCard
                type="Ether"
                bgColor="linear-gradient(82deg, #DEDEDE 5.49%, #E6E6E6 48.11%, #EAEAEA 96.19%)"
                icon={EtherCoin}
                coinName={"Ethereum"}
                currency={"ETH"}
                amount={(
                  (depositAmount * rateTG2USD) /
                  rateETH2USD
                ).toPrecision(3)}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Deposit;
