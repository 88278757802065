import { createSlice } from "@reduxjs/toolkit";

export const footerStateReducer = createSlice({
  name: "footer_state",
  initialState: {
    value: 1,
    hidden: false,
  },
  reducers: {
    setFooterState: (state, action) => {
      state.value = action.payload;
    },
    setFooterHidden: (state, action) => {
      state.hidden = action.payload;
    }
  },
});

export const { setFooterState, setFooterHidden } = footerStateReducer.actions;
export default footerStateReducer.reducer;
