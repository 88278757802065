import React, { useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getUserProfile } from "../middleware/userMiddleware";
import { Box, useTheme, Button } from "@mui/material";
import Navbar from "../components/Navbar";
import TournamentFooter from "../components/TournamentFooter";
import PrizeCard from "../components/PrizeCard";

import TMainIcon from "../assets/images/t_main_2.png";
import TSub from "../assets/images/t_sub.svg";
import Man1 from "../assets/images/man2.png";
import Man2 from "../assets/images/man3.png";
import PrizePoolImage from "../assets/images/prize_pool.png";
import Female1 from "../assets/images/female1.png";
import Preview1 from "../assets/images/t_preview_1.png";
import Preview2 from "../assets/images/t_preview_2.png";
import Preview3 from "../assets/images/t_preview_3.svg";

import SecondMan from "../assets/images/2st_man.png";
import FirstMan from "../assets/images/1st_man.png";
import ThirdMan from "../assets/images/3st_man.png";
import SecondMark from "../assets/images/2st_mark.png";
import FirstMark from "../assets/images/1st_mark.png";
import ThirdMark from "../assets/images/3st_mark.png";
import SecondTower from "../assets/images/2st_tower.png";
import FirstTower from "../assets/images/1st_tower.png";
import ThirdTower from "../assets/images/3st_tower.png";
import USDTCard from "../assets/images/usdt_coin_card.png";
import TGCoinCard from "../assets/images/tg_coin_card.png";
import TetherCard from "../assets/images/tether_coin_card.png";
import NFTCard from "../assets/images/nft_coin_card.png";
import RankingCardIcon from "../assets/images/ranking_card.png";
import FranceMan from "../assets/images/france_man.png";
import WonPrizeIcon from "../assets/images/won_prize_card.png";
import PMoneyIcon from "../assets/images/p_money.svg";
import PNFTIcon from "../assets/images/p_nft.svg";
import PStarIcon from "../assets/images/p_star.svg";
import PTonIcon from "../assets/images/p_ton.svg";
import CupIcon from "../assets/images/cup.svg";
import WinIcon from "../assets/images/check.svg";
import FailIcon from "../assets/images/cross.svg";
import WinnerAvatar from "../assets/images/winner_avatar.png";
import LoserAvatar from "../assets/images/loser_avatar.png";
import EmptyCircle from "../assets/images/empty_circle.png";
import CloseIcon from "../assets/images/close.svg";
import MoreBtn from "../assets/images/moreBtn.svg";
import BSquare from "../assets/images/BigSquare.svg";
import SSquare from "../assets/images/SmSquare.svg";
import TournamentImg from "../assets/images/TournamentImg.png";
import MoneyIcon from "../assets/images/money_icon.svg";
import MoneyCardIcon from "../assets/images/money_card_icon.svg";

import RankingCard from "../components/RankingCard";

import { getRankingData } from "../features/globalSlice";
import { LanguageFlags, Countries, Languages } from "../Constant";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import CountdownTimer from "../components/CountdownTimer";
import dayjs from "dayjs";

const TournamentsDetail = () => {
  const theme = useTheme();
  const [search] = useSearchParams();
  const dispatch = useDispatch();
  const userProfile = useSelector((state) => state.user.userProfile);
  const navigate = useNavigate();
  const id = search.get('id');
  const { data, isLoading } = useQuery({
    queryKey: ['tournament', {
      id: id
    }],
    queryFn: async () => {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/tournaments/${id}`);
      return response?.data?.tournament;
    },
    enabled: !!id
  });

  const { mutate: signup, isLoading: isMutating } = useMutation({
    mutationFn: async () => {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/tournaments/${id}/users`);

      if (response?.data?.status == 'success') {
        return response?.data;
      }

      if (response?.data?.status == 'error' && response?.data?.message) {
        throw new Error(response?.data?.message)
      }
    },
    onSuccess: () => {
      setNeedSignUp(false);
    },
    onError: (error) => {
      alert(error.message);
    }
  })

  useEffect(() => {
    dispatch(getUserProfile());
  }, [dispatch]);

  const [moveRightState, setMoveRightState] = React.useState(false);
  const [selectedButton, setselectedButton] = React.useState("detail");
  const [choosePrize, setChoosePrize] = React.useState(false);
  const [needSignUp, setNeedSignUp] = useState(true);
  const [depositModalOpen, setDepositModalOpen] = useState(false);

  useEffect(() => {
    if (data?.is_entry) {
      setNeedSignUp(false);
    }
  }, [data?.is_entry]);

  const handleSignup = async () => {
    if (isMutating) {
      return;
    }

    await signup();
  }

  const handleChoosePrize = async () => {
    if (data?.status == 'completed') {
      return;
    }

    if (!needSignUp) {
      navigate(`/game?id=${data?.game?.id}`, {
        state: {
          backUrl: `/tournaments_detail?id=${id}`
        }
      });
      return;
    }

    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/users/me`);
      const user = response?.data?.user;
      const balanceColumn = data.bonus_type_text == 'POINT' ? 'points' : 'tgg_mount';
      if (user?.[balanceColumn] > data.entry_fee) {
        setChoosePrize(true);
      } else {
        setDepositModalOpen(true);
      }
    } catch (e) {
      console.log(e);
      alert(e?.response?.data?.message || 'please try again');
    }
  };

  const rankingData = data?.users || [];
  const renderModalContent = () => {
    if (needSignUp) {
      return <>
        <p className="text-sm my-5">Deposit <span className="text-lg text-yellow-500">{Number(data?.entry_fee)} {data?.bonus_type_text}</span> to Entry</p>
        <div className={`w-full py-2 px-20 text-white rounded-full bg-[#0098EA] text-sm mb-2 cursor-pointer ${isMutating ? 'opacty-50' : ''}`} onClick={handleSignup}>Deposit</div>
      </>;
    }

    if (!needSignUp) {
      return <>
        <img className="w-20 h-20" src={'/tournament_success.png'} />
        <p className="text-sm my-5">You are in for tournament!</p>
        <div className={`w-full py-2 px-20 text-white rounded-full bg-[#0098EA] text-sm mb-2 cursor-pointer ${isMutating ? 'opacty-50' : ''}`} onClick={handleChoosePrize}>Play</div>
      </>;
    }
  }

  const renderCountDown = useMemo(() => () => {
    if (!data) {
      return null;
    }

    if (data.status === 'completed') {
      return null
    }

    if (data.status === 'not_started') {
      const seconds = Math.floor(dayjs(data.start_at).utc(true).diff(dayjs()) / 1000);
      return <>
        <p className="text-sm">Starts in</p>
        <CountdownTimer seconds={seconds} />
      </>
    }

    const seconds = Math.floor(dayjs(data.end_at).utc(true).diff(dayjs()) / 1000);
    return <>
        <p className="text-sm">Ends in</p>
        <CountdownTimer seconds={seconds} />
      </>
  }, [!!data]);

  const renderBonusIcon = useMemo(() => () => {
    return data?.bonus_type_text == 'POINT'
     ? <img className="w-3 h-3" src={MoneyIcon} alt="Cup Icon" />
     : <img className="w-3 h-3" src={MoneyCardIcon} alt="Cup Icon" />;
  }, [data?.bonus_type_text]);

  // 2000k
  return (
    <Box>
      <Navbar />
      <Box sx={{ px: "21px" }}>
        <div className="p-2 bg-[#F1F1F2] rounded-3xl flex flex-col items-center gap-3 mb-4">
          {isLoading && <div className="w-full rounded-lg h-[200px] bg-slate-100 animate-pulse"></div>}
          {data?.game && <img className="w-full rounded-lg" src={data?.game?.banner} alt="Image" />}
          {renderCountDown()}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "4px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "-4px",
              }}
            >
              {data?.users?.[0] && <img className="w-4 h-4" style={{ zIndex: 4 }} src={`https://api.multiavatar.com/${data?.users?.[0]?.telegram_id}.png`} />}
              {data?.users?.[1] && <img className="w-4 h-4" style={{ zIndex: 2, marginLeft: "-8px" }} src={`https://api.multiavatar.com/${data?.users?.[1]?.telegram_id}.png`} />}
              {data?.users?.[2] && <img className="w-4 h-4" style={{ zIndex: 1, marginLeft: "-8px" }} src={`https://api.multiavatar.com/${data?.users?.[2]?.telegram_id}.png`} />}
            </Box>
            <p style={{ fontSize: "12px", color: "#505057", lineHeight: "14px" }}>
              {data?.users_count ?? '--'} friends are playing
            </p>
          </Box>
          <div className={`py-2 px-20 text-white rounded-full ${data?.status == 'completed' ? 'bg-slate-300' : 'bg-tgBlue'} text-sm mb-2 cursor-pointer`} onClick={handleChoosePrize}>Play Now</div>
        </div>
        <Box
          sx={{
            borderRadius: "100px",
            padding: "4px 6px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: "rgba(241, 241, 242, 1)",
            marginBottom: "15px",
          }}
        >
          <Box
            sx={{
              flex: 1,
              borderRadius: "20px",
              background:
                selectedButton === "detail" ? "rgba(0, 152, 234, 1)" : "unset",
              cursor: "pointer",
              padding: "4px",
            }}
            onClick={() => setselectedButton("detail")}
          >
            <p
              style={{
                color:
                  selectedButton === "detail"
                    ? "white"
                    : "rgba(117, 117, 122, 1)",
                fontSize: "12px",
              }}
            >
              Prize Pool
            </p>
          </Box>
          <Box
            sx={{
              flex: 1,
              borderRadius: "20px",
              background:
                selectedButton === "ranking" ? "rgba(0, 152, 234, 1)" : "unset",
              cursor: "pointer",
              padding: "4px",
            }}
            onClick={() => setselectedButton("ranking")}
          >
            <p
              style={{
                color:
                  selectedButton === "ranking"
                    ? "white"
                    : "rgba(117, 117, 122, 1)",
                fontSize: "12px",
              }}
            >
              Ranking
            </p>
          </Box>
        </Box>
      </Box>
      <Box sx={{ marginBottom: "28px" }}>
        {selectedButton === "detail" ? (
          <div className="mx-4">
            {isLoading && <div className="bg-[#F1F1F2] rounded-3xl py-4 px-8 space-y-3">
              <div className="h-3 w-1/2 bg-slate-200"></div>
              <div className="h-3 w-2/3 bg-slate-200"></div>
              <div className="h-3 w-1/3 bg-slate-200"></div>
              <div className="h-3 w-2/3 bg-slate-200"></div>
              <div className="h-3 w-2/3 bg-slate-200"></div>
            </div>}
            {data && <div className="bg-[#F1F1F2] rounded-3xl py-4 px-8">
              {/* <div className="text-xs">$150 prize pool split between top 5 scores</div> */}
              <div className="text-xs mt-3 flex flex-col gap-3">
                <div className="flex flex-row">
                  <div className="w-3 h-3 mr-2 mt-0.5"><svg className="w-3 h-3" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M6 0C9.31371 0 12 2.68629 12 6C12 9.31371 9.31371 12 6 12C2.68629 12 0 9.31371 0 6C0 2.68629 2.68629 0 6 0ZM8.69695 4.22461L5.2262 7.69536L3.30305 5.77221C3.13568 5.60484 2.86432 5.60484 2.69695 5.77221C2.52959 5.93958 2.52959 6.21093 2.69695 6.3783L4.92315 8.6045C5.09052 8.77187 5.36188 8.77187 5.52925 8.6045L9.30305 4.8307C9.47041 4.66333 9.47041 4.39198 9.30305 4.22461C9.13568 4.05724 8.86432 4.05724 8.69695 4.22461Z" fill="url(#paint0_linear_1210_9260)"/>
                  <defs>
                  <linearGradient id="paint0_linear_1210_9260" x1="12" y1="6" x2="2.68775e-08" y2="6" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#0098EA"/>
                  <stop offset="1" stopColor="#4F56DD"/>
                  </linearGradient>
                  </defs>
                  </svg></div>
                  <p className="text-left text-pretty"><span className="text-[#3940BE] mr-1">Entry condition:</span> <span className="font-bold mr-1">{Number(data?.entry_fee)}</span>{data?.bonus_type_text}s</p>
                </div>
                <div className="flex flex-row">
                  <div className="w-3 h-3 mr-2 mt-0.5"><svg className="w-3 h-3" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M6 0C9.31371 0 12 2.68629 12 6C12 9.31371 9.31371 12 6 12C2.68629 12 0 9.31371 0 6C0 2.68629 2.68629 0 6 0ZM8.69695 4.22461L5.2262 7.69536L3.30305 5.77221C3.13568 5.60484 2.86432 5.60484 2.69695 5.77221C2.52959 5.93958 2.52959 6.21093 2.69695 6.3783L4.92315 8.6045C5.09052 8.77187 5.36188 8.77187 5.52925 8.6045L9.30305 4.8307C9.47041 4.66333 9.47041 4.39198 9.30305 4.22461C9.13568 4.05724 8.86432 4.05724 8.69695 4.22461Z" fill="url(#paint0_linear_1210_9260)"/>
                  <defs>
                  <linearGradient id="paint0_linear_1210_9260" x1="12" y1="6" x2="2.68775e-08" y2="6" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#0098EA"/>
                  <stop offset="1" stopColor="#4F56DD"/>
                  </linearGradient>
                  </defs>
                  </svg></div>
                  <p className="text-left text-pretty"><span className="text-[#3940BE] mr-1">Prize condition:</span> Top 10 players share prize pool {data?.bonus_type_text}s</p>
                </div>
                <div className="flex flex-row">
                  <div className="w-3 h-3 mr-2 mt-0.5"><svg className="w-3 h-3" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M6 0C9.31371 0 12 2.68629 12 6C12 9.31371 9.31371 12 6 12C2.68629 12 0 9.31371 0 6C0 2.68629 2.68629 0 6 0ZM8.69695 4.22461L5.2262 7.69536L3.30305 5.77221C3.13568 5.60484 2.86432 5.60484 2.69695 5.77221C2.52959 5.93958 2.52959 6.21093 2.69695 6.3783L4.92315 8.6045C5.09052 8.77187 5.36188 8.77187 5.52925 8.6045L9.30305 4.8307C9.47041 4.66333 9.47041 4.39198 9.30305 4.22461C9.13568 4.05724 8.86432 4.05724 8.69695 4.22461Z" fill="url(#paint0_linear_1210_9260)"/>
                  <defs>
                  <linearGradient id="paint0_linear_1210_9260" x1="12" y1="6" x2="2.68775e-08" y2="6" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#0098EA"/>
                  <stop offset="1" stopColor="#4F56DD"/>
                  </linearGradient>
                  </defs>
                  </svg></div>
                  <p className="text-left text-pretty"><span className="text-[#3940BE] mr-1">Prize pool:</span> {Number(data?.pool_bonus)} {data?.bonus_type_text}s</p>
                </div>
                {/* <div className="flex flex-row">
                  <div className="w-3 h-3 mr-2 mt-0.5">
                    <svg className="w-3 h-3" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M6 0C9.31371 0 12 2.68629 12 6C12 9.31371 9.31371 12 6 12C2.68629 12 0 9.31371 0 6C0 2.68629 2.68629 0 6 0ZM8.69695 4.22461L5.2262 7.69536L3.30305 5.77221C3.13568 5.60484 2.86432 5.60484 2.69695 5.77221C2.52959 5.93958 2.52959 6.21093 2.69695 6.3783L4.92315 8.6045C5.09052 8.77187 5.36188 8.77187 5.52925 8.6045L9.30305 4.8307C9.47041 4.66333 9.47041 4.39198 9.30305 4.22461C9.13568 4.05724 8.86432 4.05724 8.69695 4.22461Z" fill="url(#paint0_linear_1210_9260)"/>
                    <defs>
                    <linearGradient id="paint0_linear_1210_9260" x1="12" y1="6" x2="2.68775e-08" y2="6" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#0098EA"/>
                    <stop offset="1" stopColor="#4F56DD"/>
                    </linearGradient>
                    </defs>
                    </svg>
                  </div>
                  <p className="text-left text-pretty">One player can only play once</p>
                </div> */}
              </div>
            </div>}
          </div>
        ) : (
          <>
            <Box
              sx={{
                pt: choosePrize ? "29px" : "45px",
                px: "21px",
                background:
                  "linear-gradient(180deg, #FFF 2.51%, #0098EA 37.22%, #0098EA 76.27%, #FFF 97.43%)",
                position: "relative",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-end",
                  justifyContent: "center",
                }}
              >
                <Box sx={{ flex: 1 }}>
                  <Box sx={{ px: "17.94px" }}>
                    <div className="flex flex-col items-center">
                      <img
                        style={{
                          border:"2px solid white",
                          borderRadius: "50%",
                        }}
                        src={`https://api.multiavatar.com/${rankingData?.[1]?.telegram_id}.png`}
                        height={80}
                        width={80}
                        alt="People Avatar"
                      />
                      <svg className="-mt-4" width="28" height="28" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.4947 0.872977C14.4256 0.332969 15.5744 0.332969 16.5053 0.872977L21.4875 3.76308L26.4815 6.63272C27.4146 7.1689 27.989 8.16382 27.9868 9.24002L27.975 14.9998L27.9868 20.7595C27.989 21.8357 27.4146 22.8306 26.4815 23.3668L21.4875 26.2364L16.5053 29.1265C15.5744 29.6665 14.4256 29.6665 13.4947 29.1265L8.5125 26.2364L3.51851 23.3668C2.5854 22.8306 2.01098 21.8357 2.01319 20.7595L2.025 14.9998L2.01319 9.24002C2.01098 8.16382 2.5854 7.1689 3.51851 6.63272L8.5125 3.76308L13.4947 0.872977Z" fill="#CECDD2"/>
                        <g clip-path="url(#clip0_1210_9375)">
                        <path d="M15 11.2498L17.5 14.9998L20.625 12.4998L19.375 18.7498H10.625L9.375 12.4998L12.5 14.9998L15 11.2498Z" fill="white" stroke="white" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round"/>
                        </g>
                        <defs>
                        <clipPath id="clip0_1210_9375">
                        <rect width="15" height="15" fill="white" transform="translate(7.5 7.49976)"/>
                        </clipPath>
                        </defs>
                      </svg>
                    </div>
                    <p className="text-xs font-medium my-2 text-white w-20 truncate">
                      {rankingData?.[1]?.username}
                    </p>
                    <div className="flex flex-col items-center px-2 py-1 bg-white rounded-md mb-5">
                      <p className="text-xs font-medium">
                        {rankingData?.[1]?.pivot?.max_score}
                      </p>
                      <div className="flex flex-row items-center text-xs gap-1">
                        {renderBonusIcon()}
                        {rankingData?.[1]?.bonus}
                      </div>
                    </div>
                  </Box>
                  <img src="/Rank-2.png" alt="Tower Icon" />
                </Box>
                <Box sx={{ flex: 1 }}>
                  <Box sx={{ px: "17.94px" }}>
                    <div className="flex flex-col items-center">
                      <img
                        style={{
                          border:"2px solid white",
                          borderRadius: "50%",
                        }}
                        src={`https://api.multiavatar.com/${rankingData?.[0]?.telegram_id}.png`}
                        height={80}
                        width={80}
                        alt="People Avatar"
                      />
                      <svg className="-mt-4" width="28" height="28" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.4947 0.872977C14.4256 0.332969 15.5744 0.332969 16.5053 0.872977L21.4875 3.76308L26.4815 6.63272C27.4146 7.1689 27.989 8.16382 27.9868 9.24002L27.975 14.9998L27.9868 20.7595C27.989 21.8357 27.4146 22.8306 26.4815 23.3668L21.4875 26.2364L16.5053 29.1265C15.5744 29.6665 14.4256 29.6665 13.4947 29.1265L8.5125 26.2364L3.51851 23.3668C2.5854 22.8306 2.01098 21.8357 2.01319 20.7595L2.025 14.9998L2.01319 9.24002C2.01098 8.16382 2.5854 7.1689 3.51851 6.63272L8.5125 3.76308L13.4947 0.872977Z" fill="#FFD54B"/>
                        <g clip-path="url(#clip0_1210_9375)">
                        <path d="M15 11.2498L17.5 14.9998L20.625 12.4998L19.375 18.7498H10.625L9.375 12.4998L12.5 14.9998L15 11.2498Z" fill="white" stroke="white" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round"/>
                        </g>
                        <defs>
                        <clipPath id="clip0_1210_9375">
                        <rect width="15" height="15" fill="white" transform="translate(7.5 7.49976)"/>
                        </clipPath>
                        </defs>
                      </svg>
                    </div>
                    <p className="text-xs font-medium my-2 text-white w-20 truncate">
                      {rankingData?.[0]?.username}
                    </p>
                    <div className="flex flex-col items-center px-2 py-1 bg-white rounded-md mb-5">
                      <p className="text-xs font-medium">
                        {rankingData?.[0]?.pivot?.max_score}
                      </p>
                      <div className="flex flex-row items-center text-xs gap-1">
                        {renderBonusIcon()}
                        {rankingData?.[0]?.bonus}
                      </div>
                    </div>
                  </Box>
                  <img src="/Rank-1.png" alt="Tower Icon" />
                </Box>
                <Box sx={{ flex: 1 }}>
                  <Box sx={{ px: "17.94px" }}>
                    <div className="flex flex-col items-center">
                      <img
                        style={{
                          border:"2px solid white",
                          borderRadius: "50%",
                        }}
                        src={`https://api.multiavatar.com/${rankingData?.[2]?.telegram_id}.png`}
                        height={80}
                        width={80}
                        alt="People Avatar"
                      />
                      <svg className="-mt-4" width="28" height="28" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.4947 0.872977C14.4256 0.332969 15.5744 0.332969 16.5053 0.872977L21.4875 3.76308L26.4815 6.63272C27.4146 7.1689 27.989 8.16382 27.9868 9.24002L27.975 14.9998L27.9868 20.7595C27.989 21.8357 27.4146 22.8306 26.4815 23.3668L21.4875 26.2364L16.5053 29.1265C15.5744 29.6665 14.4256 29.6665 13.4947 29.1265L8.5125 26.2364L3.51851 23.3668C2.5854 22.8306 2.01098 21.8357 2.01319 20.7595L2.025 14.9998L2.01319 9.24002C2.01098 8.16382 2.5854 7.1689 3.51851 6.63272L8.5125 3.76308L13.4947 0.872977Z" fill="#DD8525"/>
                        <g clip-path="url(#clip0_1210_9375)">
                        <path d="M15 11.2498L17.5 14.9998L20.625 12.4998L19.375 18.7498H10.625L9.375 12.4998L12.5 14.9998L15 11.2498Z" fill="white" stroke="white" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round"/>
                        </g>
                        <defs>
                        <clipPath id="clip0_1210_9375">
                        <rect width="15" height="15" fill="white" transform="translate(7.5 7.49976)"/>
                        </clipPath>
                        </defs>
                      </svg>
                    </div>
                    <p className="text-xs font-medium my-2 text-white w-20 truncate">
                      {rankingData?.[2]?.username}
                    </p>
                    <div className="flex flex-col items-center px-2 py-1 bg-white rounded-md mb-5">
                      <p className="text-xs font-medium">
                        {rankingData?.[2]?.pivot?.max_score}
                      </p>
                      <div className="flex flex-row items-center text-xs gap-1">
                        {renderBonusIcon()}
                        {rankingData?.[2]?.bonus}
                      </div>
                    </div>
                  </Box>
                  <img src="/Rank-3.png" alt="Tower Icon" />
                </Box>
              </Box>
            </Box>
            <div className="-mt-10 p-4 bg-white rounded-t-2xl flex flex-col items-center justify-center gap-3 z-10 relative">
              {rankingData.slice(3).map((item, index) => (
                <RankingCard
                  key={index}
                  rank={4 + index}
                  user={item}
                  bonus_icon={renderBonusIcon()}
                />
              ))}
            </div>
          </>
        )}
      </Box>
      <Box
        sx={{
          position: "absolute",
          display: choosePrize ? "flex" : "none",
          width: "100%",
          height: "100%",
          // background: moveRightState ? "rgba(0, 0, 0, 0.44)" : "unset",
          background: "rgba(0, 0, 0, 0.44)",
          top: "0",
          left: "0",
          transition: "all 0.4s",
          alignItems: "flex-end",
          justifyContent: "center",
          zIndex: 99,
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            padding: "12px 21px 24px",
            position: "relative",
            background: "white",
            width: "100%",
            borderTopRightRadius: "10px",
            borderTopLeftRadius: "10px",
          }}
        >
          <img
            style={{
              position: "absolute",
              top: "12px",
              right: "16px",
              cursor: "pointer",
            }}
            src={CloseIcon}
            alt="Close Icon"
            onClick={() => setChoosePrize(false)}
          />
          <Box
            sx={{
              marginBottom: "14px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "5px",
            }}
          >
            <img src={CupIcon} alt="Cup Icon" />
            <p
              style={{
                fontSize: "15px",
                fontWeight: "600",
                lineHeight: "24px",
                color: "#252946",
              }}
            >
              Deposit {data?.bonus_type_text}
            </p>
          </Box>
          <div className="flex flex-col items-center">
            {renderModalContent()}
            {/* <div className="py-3 px-2 rounded-2xl relative overflow-hidden flex flex-col" style={{
              background: "linear-gradient(79deg, #F99C39 0%, #FF8606 100%)",
            }}>
              <img
                style={{
                  position: "absolute",
                  opacity: "0.3",
                  left: "-23px",
                  top: "-44px",
                }}
                src={BSquare}
                alt="Icon"
              />
              <img
                style={{
                  position: "absolute",
                  opacity: "0.3",
                  right: "-6px",
                  top: "-84px",
                }}
                src={BSquare}
                alt="Icon"
              />
              <img
                style={{
                  position: "absolute",
                  opacity: "0.3",
                  right: "112px",
                  top: "-40px",
                }}
                src={SSquare}
                alt="Icon"
              />
              <img
                style={{
                  position: "absolute",
                  opacity: "0.3",
                  right: "84px",
                  top: "81px",
                }}
                src={SSquare}
                alt="Icon"
              />
              <div className="flex flex-row items-center justify-between bg-white/15 rounded p-1 pl-2">
                <p className="text-white text-xs font-semibold">
                  POINT
                </p>
                <img className="w-4 h-4" src={MoreBtn} alt="Icon" />
              </div>
              <div className="flex-1 flex items-center justify-center py-3">
                <p className="text-center text-white">$<span className="text-3xl font-bold mx-1">4.80</span>USD</p>
              </div>
              <div className="flex flex-row items-center justify-between">
                <p className="text-white text-sm ml-2">
                  Entry
                </p>
                <div className="flex flex-row items-center justify-center gap-1 py-0.5 px-1 rounded-md bg-white">
                  <p
                    style={{
                      color: "#F99C39",
                      fontSize: "11.817px",
                      fontWeight: "500",
                      lineHeight: "16.881px",
                    }}
                  >
                    50
                  </p>
                  <img src={TGCoinCard} alt="Icon" />
                </div>
              </div>
            </div>
            <div className="py-3 px-2 rounded-2xl relative overflow-hidden flex flex-col" style={{
              background: "linear-gradient(82.05deg, #4147C8 5.49%, #4A52DF 96.19%)",
            }}>
              <img
                style={{
                  position: "absolute",
                  opacity: "0.3",
                  left: "-23px",
                  top: "-44px",
                }}
                src={BSquare}
                alt="Icon"
              />
              <img
                style={{
                  position: "absolute",
                  opacity: "0.3",
                  right: "-6px",
                  top: "-84px",
                }}
                src={BSquare}
                alt="Icon"
              />
              <img
                style={{
                  position: "absolute",
                  opacity: "0.3",
                  right: "112px",
                  top: "-40px",
                }}
                src={SSquare}
                alt="Icon"
              />
              <img
                style={{
                  position: "absolute",
                  opacity: "0.3",
                  right: "84px",
                  top: "81px",
                }}
                src={SSquare}
                alt="Icon"
              />
              <div className="flex flex-row items-center justify-between bg-white/15 rounded p-1 pl-2">
                <p className="text-white text-xs font-semibold">
                  TGG
                </p>
                <img className="w-4 h-4" src={MoreBtn} alt="Icon" />
              </div>
              <div className="flex-1 flex items-center justify-center py-3">
                <p className="text-center text-white">$<span className="text-3xl font-bold mx-1">4.80</span>USD</p>
              </div>
              <div className="flex flex-row items-center justify-between">
                <p className="text-white text-sm ml-2">
                  Entry
                </p>
                <div className="flex flex-row items-center justify-center gap-1 py-0.5 px-1 rounded-md bg-white">
                  <p
                    style={{
                      color: "#F99C39",
                      fontSize: "11.817px",
                      fontWeight: "500",
                      lineHeight: "16.881px",
                    }}
                  >
                    50
                  </p>
                  <img src={TGCoinCard} alt="Icon" />
                </div>
              </div>
            </div> */}
          </div>
          
        </Box>
      </Box>
      <Box
        sx={{
          position: "absolute",
          display: depositModalOpen ? "flex" : "none",
          width: "100%",
          height: "100%",
          // background: moveRightState ? "rgba(0, 0, 0, 0.44)" : "unset",
          background: "rgba(0, 0, 0, 0.44)",
          top: "0",
          left: "0",
          transition: "all 0.4s",
          alignItems: "flex-end",
          justifyContent: "center",
          zIndex: 99,
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            padding: "12px 21px 24px",
            position: "relative",
            background: "white",
            width: "100%",
            borderTopRightRadius: "10px",
            borderTopLeftRadius: "10px",
          }}
        >
          <img
            style={{
              position: "absolute",
              top: "12px",
              right: "16px",
              cursor: "pointer",
            }}
            src={CloseIcon}
            alt="Close Icon"
            onClick={() => setDepositModalOpen(false)}
          />
          <div className="mb-2 flex flex-row items-center justify-center">
            <img className="w-10 h-10 z-10" src={MoneyIcon} alt="Cup Icon" />
            <img className="w-9 h-9 -ml-4" src={MoneyCardIcon} alt="Cup Icon" />
          </div>
          <div className="flex flex-col items-center">
            <p className="text-sm my-5">Insufficient points or TGG coins to play. Refill to play this game!</p>
            <div className={`w-full py-2 px-20 text-white rounded-full bg-[#0098EA] text-sm mb-2 cursor-pointer`} onClick={() => {
              navigate('/deposit');
            }}>Purchase in Wallet</div>
          </div>
        </Box>
      </Box>
      {/* <TournamentFooter
        handleChoosePrize={handleChoosePrize}
        choosePrize={choosePrize}
      /> */}
    </Box>
  );
};

export default TournamentsDetail;
