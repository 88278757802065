import React, { Fragment, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Box,
  useTheme,
  Button,
  Typography,
  Alert,
  Drawer,
  TextField,
  Grid,
  CircularProgress,
} from '@mui/material'
import GameCard from '../components/GameCard'
import axios from 'axios'
import { useQuery } from '@tanstack/react-query'

export default function GamesCard({ handleSetGameState }) {
  const { data: games, isLoading } = useQuery({
    queryKey: ['games'],
    queryFn: async () => {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/game`)
      return response?.data?.games || []
    },
  })
  const theme = useTheme()
  const [current, setCurrent] = useState(0)
  const changeTab = (active) => {
    setCurrent(active)
  }
  return (
    <Box>
      <Box
          className="w-full h-full px-6 pt-[20%] overflow-hidden relative"
          style={{
            aspectRatio: '372 / 662',
            background: 'url(/gamesBg.png) no-repeat',
            backgroundSize: '100% auto',
          }}
      >
        <Box className="flex z-10 relative items-center w-full h-8 bg-[#F7F9FB] rounded-[100px]">
          <div
            onClick={() => changeTab(0)}
            className={`flex-1 h-6 rounded-[100px] leading-6 ${
              current === 0 ? 'bg-tgBlue text-white' : 'text-tgBlue'
            }`}
          >
            🔥Point
          </div>
          <div
            onClick={() => changeTab(1)}
            className={`flex-1 h-6 rounded-[100px] leading-6 ${
              current === 1 ? 'bg-tgBlue text-white' : 'text-tgBlue'
            }`}
          >
            💰TGG
          </div>
        </Box>
        <Box className="mt-4 h-[90%] pb-6 games-wrapper overflow-y-auto">
          {isLoading &&
            [0, 1, 2].map((i) => (
              <div
                key={i}
                className="px-3 py-2 bg-[#F7F9FB] mb-3 rounded-lg animate-pulse"
              >
                <div className="flex flex-row items-center gap-2">
                  <div className="w-[60px] h-[60px] rounded-md bg-slate-200"></div>
                  <div className="space-y-2">
                    <div className="bg-slate-200 w-12 h-3"></div>
                    <div className="bg-slate-200 w-24 h-3"></div>
                  </div>
                </div>
              </div>
            ))}
          <div className="scroll-content overflow-y-scroll overflow-x-hidden flex-1">
            {games?.map((item, index) => (
              <Box
                key={index}
                sx={{
                  padding: '13px 11px 14px 11px',
                  background: '#F7F9FB',
                  borderRadius: '8px',
                  marginBottom: '12px',
                  zIndex: 2,
                  position: 'relative',
                }}
              >
                <GameCard
                  game={item}
                  icon={item.avatar}
                  amount={item.coinAmount}
                  setGameState={handleSetGameState}
                  url={item.url}
                  index={index}
                >
                  <p
                    style={{
                      color: theme.palette.black[0],
                      fontSize: '16px',
                      maxWidth: '170px',
                      fontWeight: 600,
                    }}
                  >
                    {item.title}
                    {/*          <br/>*/}
                    {/*          <span className="text-xs">*/}
                    {/*  {item?.headline || 'Free to play & earn point'}*/}
                    {/*</span>*/}
                  </p>
                  <div className="border border-[#F99C39] w-[48px] h-4 rounded-full text-[#F99C39] text-[10px] text-center leading-4">
                    Point
                  </div>
                  <div className="flex flex-nowrap items-center">
                    <div className="flex">
                      <img
                        className="border-2 border-white rounded-full w-6 h-6 z-30"
                        src="https://d35snvnyqovq3n.cloudfront.net/banners/east.jpg"
                        alt=""
                      />
                      <img
                        className="border-2 border-white rounded-full w-6 h-6 -ml-2 z-20"
                        src="https://d35snvnyqovq3n.cloudfront.net/banners/east.jpg"
                        alt=""
                      />
                      <img
                        className="border-2 border-white rounded-full w-6 h-6 -ml-2 z-10"
                        src="https://d35snvnyqovq3n.cloudfront.net/banners/east.jpg"
                        alt=""
                      />
                    </div>
                    <div
                      style={{ fontFamily: 'Roboto' }}
                      className="text-[#505057] text-sm flex-shrink-0"
                    >
                      3,471 online
                    </div>
                  </div>
                </GameCard>
              </Box>
            ))}
          </div>
        </Box>
        {/*<img*/}
        {/*  src="/gamesBg.png"*/}
        {/*  style={{*/}
        {/*    position: 'absolute',*/}
        {/*    top: 0,*/}
        {/*    left: 0,*/}
        {/*    aspectRatio: '1/2.5660919540229883',*/}
        {/*  }}*/}
        {/*  alt=""*/}
        {/*/>*/}
      </Box>
    </Box>
  )
}
