import React, { useEffect, useState } from "react";
import { Box, useTheme, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useSelector, useDispatch } from "react-redux";
import { setBackState, increaseIndex } from "../features/navbarSlice";
import { setFooterState } from "../features/footerSlice";

import { getUserProfile } from "../middleware/userMiddleware";

import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import TournamentCard from "../components/TournamentCard";
import UserProfileCard from '../components/UserProfileCard';

import MoneyNCard from "../assets/images/money_game.png";
import Moneys from "../assets/images/moneys.png";
import ProfileIcon from "../assets/images/profile.png";
import Flag from "../assets/images/France_Flag.svg";
import ZIcon from "../assets/images/zIcon.svg";
import TImg1 from "../assets/images/epic.png";
import TImg2 from "../assets/images/flap.png";
import TImg3 from "../assets/images/MineCraft.png";
import TImg4 from "../assets/images/hoppy.png";
import TImg5 from "../assets/images/farm.png";


import TMain from "../assets/images/t_main.svg";
import Man1 from "../assets/images/man1.png";
import Man2 from "../assets/images/man2.png";
import Man3 from "../assets/images/man3.png";
import Man4 from "../assets/images/man4.svg";
import TTitle from "../assets/images/t_title.svg";
import BSquare from "../assets/images/BigSquare.svg";
import SSquare from "../assets/images/SmSquare.svg";
import MoreBtn from "../assets/images/moreBtn.svg";
// import TournamentCardIcon1 from "../assets/images/tournament_card_icon_1.svg";
// import TournamentCardIcon2 from "../assets/images/tournament_card_icon_2.svg";
// import TournamentCardIcon3 from "../assets/images/tournament_card_icon_3.svg";
import TournamentCardIcon1 from "../assets/images/DuckDuckie.png";
import TournamentCardIcon2 from "../assets/images/HexaPuzzle.png";
import TournamentCardIcon3 from "../assets/images/taptap.png";
import Recommend1 from "../assets/images/recommend_1.png";
import Recommend2 from "../assets/images/recommend_2.png";
import Recommend3 from "../assets/images/recommend_3.png";

import {
  PointSystems,
  LanguageFlags,
  Countries,
  Games,
  FooterUrl,
} from "../Constant";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";

const Tournaments = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [tab, setTab] = useState('in_progress');
  const currentUrl = window.location.href.split("/")[window.location.href.split("/").length - 1];
  const { data: tournaments, isLoading } = useQuery({
    queryKey: ['tournaments', {
      status: 'in_progress'
    }],
    queryFn: async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/tournaments?status=in_progress`
      );

      return response?.data?.tournaments || [];
    }
  })

  const { data: completedTournaments, isLoading: isCompletedLoading } = useQuery({
    queryKey: ['tournaments', {
      status: 'completed'
    }],
    queryFn: async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/tournaments?status=completed`
      );

      return response?.data?.tournaments || [];
    }
  })

  useEffect(() => {
    dispatch(
      setFooterState(currentUrl === undefined ? 1 : FooterUrl["/" + currentUrl])
    );
  }, []);

  useEffect(() => {
    dispatch(getUserProfile());
  }, [dispatch]);
  
  const handleNavigate = (tournament) => {
    dispatch(
      setBackState(
        currentUrl == "" ||
          currentUrl == " " ||
          currentUrl == "undefined" ||
          currentUrl == null
          ? "/"
          : "/" + currentUrl
      )
    );
    dispatch(increaseIndex());

    navigate(`/tournaments_detail?id=${tournament.id}`);
  };

  const popular = tournaments?.[0];

  return (
    <React.Fragment>
      <Navbar />
      {/* <Box
        sx={{
          px: "21px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "46.86px",
        }}
      >
        <Box
          sx={{
            padding: "22.82px 23.81px 4.23px",
            background:
              "linear-gradient(231.61deg, #74CCFD 8.44%, #468EFE 74.37%)",
            boxShadow:
              " 0px 4.1490678787231445px 4.1490678787231445px 0px rgba(74, 147, 254, 0.27)",
            borderRadius: "24.89px",
            position: "relative",
          }}
        >
          <img
            src={MoneyNCard}
            alt="Main Icon"
            style={{
              position: "absolute",
              top: "0",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          />
          <p
            style={{
              color: "white",
              fontSize: "13.48px",
              lineHeight: "29.04px",
              fontWeight: "500",
            }}
          >
            TG Games Hold
          </p>
          <p
            style={{
              color: "white",
              fontWeight: "700",
              fontSize: "26.96px",
              lineHeight: "29.04px",
            }}
          >
            7,000
          </p>
          <p
            style={{
              color: "white",
              fontSize: "11.41px",
              lineHeight: "29.04px",
            }}
          >
            Real Cash Matches
          </p>
        </Box>
        <Box
          sx={{
            padding: "22.82px 23.81px 4.23px",
            background:
              "linear-gradient(231.61deg, #74CCFD 8.44%, #468EFE 74.37%)",
            boxShadow:
              " 0px 4.1490678787231445px 4.1490678787231445px 0px rgba(74, 147, 254, 0.27)",
            borderRadius: "24.89px",
            position: "relative",
          }}
        >
          <img
            src={Moneys}
            alt="Main Icon"
            style={{
              position: "absolute",
              top: "0",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          />
          <p
            style={{
              color: "white",
              fontSize: "13.48px",
              lineHeight: "29.04px",
              fontWeight: "500",
            }}
          >
            No.1 Player Won
          </p>
          <p
            style={{
              color: "white",
              fontWeight: "700",
              fontSize: "26.96px",
              lineHeight: "29.04px",
            }}
          >
            $6,000
          </p>
          <p
            style={{
              color: "white",
              fontSize: "11.41px",
              lineHeight: "29.04px",
            }}
          >
            Prize
          </p>
        </Box>
      </Box> */}
      {/* <Box
        sx={{
          position: "relative",
          paddingLeft: "21px",
          textAlign: "center",
          margin: `${theme.gaps[1]} 0`,
          marginTop: "14px",
          maxWidth: "354px",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "0",
            left: "53%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="50"
            height="50"
            viewBox="0 0 50 50"
            fill="none"
          >
            <circle
              cx="25"
              cy="25"
              r="23.4375"
              stroke="url(#paint0_linear_640_3608)"
              strokeWidth="3.125"
            />
            <defs>
              <linearGradient
                id="paint0_linear_640_3608"
                x1="2.34376"
                y1="32.0313"
                x2="22.2319"
                y2="-0.0348944"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#0098EA" />
                <stop offset="0.861496" stopColor="#3940BE" />
              </linearGradient>
            </defs>
          </svg>
          <Box
            style={{
              position: "absolute",
              width: "43.75px",
              height: "43.75px",
              borderRadius: "64.1",
              flexShrink: 0,
              top: "3px",
              left: "3px",
              background: `url(${userProfile?.avatar}) lightgray 50% / cover no-repeat`,
              borderRadius: "50%",
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            background: theme.bgColor[0],
            alignItems: "center",
            justifyContent: "space-between",
            borderRadius: "24px",
            padding: "9px 9px 12px 10px",
            marginBottom: "19px",
          }}
        >
          <Box sx={{ display: "flex" }}>
            <Box sx={{ marginLeft: "12px" }}>
              <p
                className="poppines_font profile_name"
                style={{
                  fontSize: "15px",
                  fontWeight: theme.fontWeight.bold,
                  lineHeight: "22.5px",
                  textAlign: "left",
                }}
              >
                {userProfile?.username || "--"}
              </p>
              <Box sx={{ display: "flex", alignItems: "center", gap: "6px" }}>
                <img
                  src={
                    LanguageFlags[userProfile?.region] || LanguageFlags["fr"]
                  }
                  alt="Flag Image"
                />
                <p
                  style={{
                    color: "#75757A",
                    fontWeight: theme.fontWeight.small,
                    fontSize: "11px",
                  }}
                >
                  {Countries[userProfile?.language] || Countries["fr"]}
                </p>
              </Box>
            </Box>
          </Box>
          <Box>
            <p
              style={{
                color: "rgba(117, 117, 122, 1)",
                fontSize: "13px",
                lineHeight: "19.5px",
              }}
            >
              Won prize
            </p>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "3px",
              }}
            >
              <img src={ZIcon} alt="Icon" />
              <p
                style={{
                  color: "rgba(117, 117, 122, 1)",
                  fontSize: "13px",
                  lineHeight: "19.5px",
                }}
              >
                19000k
              </p>
            </Box>
          </Box>
        </Box>
      </Box> */}
      {/* <Box
        sx={{
          width: "100%",
          background: "rgba(247, 249, 251, 1)",
          paddingTop: "12px",
          overflow: "hidden",
          marginBottom: "18px",
        }}
      >
        <p
          style={{
            fontWeight: "600",
            fontSize: "18px",
            lineHeight: "24px",
            textAlign: "center",
            marginBottom: "16px",
          }}
        >
          Current Tournaments
        </p>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: theme.gaps[0],
            px: "21px",
            overflowY: "hidden",
            overflowX: "auto",
            scrollbarWidth: "thin",
            scrollbarColor: "transparent transparent",
            msOverflowStyle: "none",
            transition: "all 0.7s",
            "&::-webkit-scrollbar": {
              width: 4,
            },

            "&::-webkit-scrollbar-thumb": {
              background: "transparent",
            },
          }}
        >
          <img className="cursor-pointer rounded-2xl w-28 h-28" src={TImg1} alt="T Image" />
          <img className="cursor-pointer rounded-2xl w-28 h-28" src={TImg2} alt="T Image" />
          <img className="cursor-pointer rounded-2xl w-28 h-28" src={TImg3} alt="T Image" />
          <img className="cursor-pointer rounded-2xl w-28 h-28" src={TImg4} alt="T Image" />
          <img className="cursor-pointer rounded-2xl w-28 h-28" src={TImg5} alt="T Image" />
        </Box>
      </Box> */}
      <UserProfileCard />
      <div className="px-5 mb-3">
        <p
          style={{
            fontWeight: "600",
            fontSize: "18px",
            lineHeight: "24px",
            color: "rgba(37, 41, 70, 1)",
            marginBottom: "6px",
          }}
        >
          Popular Tournament
        </p>
        {isLoading && <div className="mt-2 w-full rounded-lg h-[200px] bg-slate-200 animate-pulse"></div>}
        {popular && <div className="mt-2 rounded-lg overflow-hidden relative cursor-pointer" onClick={() => handleNavigate(popular)}>
          <img className="w-full" src={popular.game.banner} />
          <div className="flex flex-row items-center absolute bottom-4 right-4 gap-4">
            <Box>
              <div className="flex flex-row items-end justify-between -gap-2">
                <img className="w-3 h-3 z-10" src={Man1} alt="Avatar" />
                <img
                   className="w-3 h-3"
                  src={Man2}
                  style={{ marginLeft: "-5px", zIndex: 3 }}
                  alt="Avatar"
                />
                <img
                  className="w-3 h-3"
                  src={Man3}
                  style={{ marginLeft: "-5px", zIndex: 2 }}
                  alt="Avatar"
                />
                <img
                  className="w-3 h-3"
                  src={Man4}
                  style={{ marginLeft: "-5px", zIndex: 1 }}
                  alt="Avatar"
                />
              </div>
              <p className="text-lg text-white bold">{popular?.users_count}</p>
              <p className="text-white text-xs">Online</p>
            </Box>
            <img className="w-10 h-10" src={MoreBtn} alt="Square" />
          </div>
        </div>}
      </div>
      <Box sx={{ px: "21px", marginBottom: "4px", pt: "12px", pb: "13px" }}>
        <p
          style={{
            fontSize: "18px",
            lineHeight: "24px",
            color: "rgba(37, 41, 70, 1)",
            fontWeight: "600",
            marginBottom: "10px",
          }}
        >
          Prize Pool
        </p>
        <p className="text-xs px-4 mb-3">There are two kind of prize pools  including points  pool and $TGG pool</p>
        <div className="flex items-center justify-center mb-4 py-1 px-1 rounded-full bg-[#F1F1F2]">
          <div
            className={`flex-1 rounded-full cursor-pointer p-1 ${tab == 'in_progress' ? 'bg-tgBlue' : 'bg-transparent'}`}
            onClick={() => setTab("in_progress")}
          >
            <p className={`text-xs ${tab == 'in_progress' ? 'text-white' : 'text-[#75757A]'}`}>Ongoing</p>
          </div>
          <div
            className={`flex-1 rounded-full cursor-pointer p-1 ${tab == 'completed' ? 'bg-tgBlue' : 'bg-transparent'}`}
            onClick={() => setTab("completed")}
          >
            <p className={`text-xs ${tab == 'completed' ? 'text-white' : 'text-[#75757A]'}`}>
              Finished
            </p>
          </div>
        </div>
        {isLoading && [0, 1, 2].map((i) => <div key={i} className="px-3 py-2 bg-[#F7F9FB] mb-3 rounded-lg animate-pulse">
          <div className="flex flex-row items-center gap-2">
            <div className="w-[84px] h-[84px] rounded-md bg-slate-200"></div>
            <div className="space-y-2">
              <div className="bg-slate-200 w-12 h-3"></div>
              <div className="bg-slate-200 w-24 h-3"></div>
            </div>
          </div>
        </div>)}
        <div className={`space-y-2 ${tab == 'in_progress' ? 'block' : 'hidden'}`}>
          {tournaments?.map((tournament) => {
            return <TournamentCard
              key={tournament.id}
              data={tournament}
              icon={tournament.game.avatar}
              title={tournament.game.title}
              buttonColor1="rgba(114, 138, 150, 1)"
              buttonText1="FUN"
              buttonColor2="rgba(19, 161, 153, 1)"
              buttonText2="CASH"
              buttonColor3="rgba(249, 156, 57, 1)"
              buttonText3="POINT"
            />
          })}
        </div>
        <div className={`space-y-2 ${tab == 'completed' ? 'block' : 'hidden'}`}>
          {completedTournaments?.map((tournament) => {
            return <TournamentCard
              key={tournament.id}
              data={tournament}
              icon={tournament.game.avatar}
              title={tournament.game.title}
              buttonColor1="rgba(114, 138, 150, 1)"
              buttonText1="FUN"
              buttonColor2="rgba(19, 161, 153, 1)"
              buttonText2="CASH"
              buttonColor3="rgba(249, 156, 57, 1)"
              buttonText3="POINT"
            />
          })}
        </div>
      </Box>
      {/* <Box>
        <p
          style={{
            fontSize: "18px",
            fontWeight: "600",
            lineHeight: "24px",
            color: "rgba(37, 41, 70, 1)",
            marginBottom: "22px",
          }}
        >
          Recommended For You
        </p>
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "space-between",
          }}
        >
          <Box>
            <img src={Recommend1} alt="Icon" />
          </Box>
          <Box sx={{ position: "relative" }}>
            <img src={Recommend2} alt="Icon" />
            <Button
              className="poppines_font"
              variant="outlined"
              endIcon={<ArrowForwardIosIcon />}
              sx={{
                position: "absolute",
                padding: "1px 14px 2px 25px",
                borderRadius: "25px",
                border: "1px solid #FFF",
                bottom: "45px",
                left: "18px",
                color: "white !important",
                "&:hover": {
                  border: "1px solid #FFF",
                },
              }}
            >
              Play
            </Button>
          </Box>
          <Box>
            <img src={Recommend3} alt="Icon" />
          </Box>
        </Box>
      </Box> */}
    </React.Fragment>
  );
};

export default Tournaments;
