import React from "react";
import { Box, useTheme, Typography } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";

const RankingCard = (props) => {
  const { user, rank, bonus_icon } = props;

  return (
    <React.Fragment>
      <div className="w-full py-2 pl-3 pr-5 bg-white rounded-md shadow-md flex flex-row items-center justify-between">
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "14px",
            flex: 1,
          }}
        >
          <p className="text-base">
            {rank}
          </p>
          <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <img
              className="w-12 h-12 rounded-full"
              src={`https://api.multiavatar.com/${user.telegram_id}.png`}
              alt="User Avatar"
            />
            <Box>
              <p
                style={{
                  fontSize: "12px",
                  fontWeight: "600",
                  lineHeight: "20px",
                  color: "#252946",
                }}
              >
                {user.username}
              </p>
            </Box>
          </Box>
        </Box>
        <div className="flex flex-row items-center flex-1 justify-end text-xs gap-3">
          <p className="flex-1 text-right">{user.pivot.max_score}</p>
          <div className="flex-1 flex flex-row items-center justify-end gap-1">{bonus_icon} {user.bonus}</div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default RankingCard;
