import React, {useState} from "react";
import {Box} from "@mui/material";
import axios from "axios";
import {LoaderIcon} from "lucide-react";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";


//1美元对应的积分数
const pointsPerDols = 1000;

const ShopEarnGift = ({data}) => {
    const navigate = useNavigate();
    const userProfile = useSelector((state) => state.user.userProfile);
    const telegramId = userProfile?.telegram_id;
    const [pending, setPending] = useState(0);//0 关闭 1 处理中 2处理完成
    const [cardResult, setCardResult] = useState(null);
    const [showPrice, setShowPrice] = useState(null);
    const icon = data.detail.assets.gift_card_url;
    const {name: title, slug: brandSlug, currency} = data;

    let value = 0;
    let desc = "";
    if (!!data.digital_face_value_limits) {
        value = parseInt(data.digital_face_value_limits.lower);
    }
    if (!!data.digital_denominations && data.digital_denominations.length > 0) {
        value = parseInt(data.digital_denominations[0]);

    }
    if (value === 0) {
        return null;
    }
    desc = value + " " + currency;

    const doShowPrice = async () => {
        setPending(1);
        const price = await queryPrice(brandSlug, value, currency, telegramId);
        setShowPrice(price);
        setPending(2);
    }

    //兑换
    const doIssue = async () => {
        setPending(1);
        const result = await issueCard(telegramId, data.slug, value, currency);
        if (result.status !== 200) {
            console.error("issue card error", result);
            setPending(0);
            setShowPrice(null);
            return;
        }
        openCard(result);
        setPending(0);
        setShowPrice(null);
    }
    //充值
    const doRecharge = async (value) => {
        navigate('/deposit?usdt=' + value);
    }
    const openCard = (result) => {
        window.Telegram.WebApp.openLink(result.data.data.url, {
            try_instant_view: false
        })
    }

    const closeIframe = () => {
        setCardResult(null);
    }

    return (
        <React.Fragment>

            <div className="relative">
                {pending === 1 && (
                    <div className="absolute w-full h-full  flex justify-center items-center left-0 bg-zinc-300/30">
                        <LoaderIcon className="w-4 h-4 animate-spin"/>
                    </div>
                )}

                <Box className="" onClick={doShowPrice}
                     sx={{background: "rgba(247, 249, 251, 1)", padding: "10px 8px"}}>
                    <img className="rounded-lg overflow-hidden w-full aspect-[165/107]" src={icon} alt="Main"/>
                    <p
                        style={{fontWeight: "600", fontSize: "12px", lineHeight: "17.46px"}}
                    >
                        {title}
                    </p>
                    <p style={{fontSize: "10px", lineHeight: "14.55px"}}>{desc}</p>
                </Box>
            </div>
            {/* {consumedPoints,consumedUsdt,remainingPoints,remainingUsdt} */}
            {pending !== 0 && (
                <div onClick={e => {
                    e.preventDefault();
                    //运行期间不允许关闭
                    if (pending === 2) {
                        setShowPrice(null);
                        setPending(0);
                    }
                }} className=" z-50 bg-gray-200/50 absolute top-0 left-0 w-full h-full"></div>
            )}
            {!!showPrice && (
                <>
                    <div className=" z-50 bg-transparent  absolute left-0 bottom-24 w-screen px-2">
                        <div className="relative w-full h-full">
                            <img className="w-full  absolute left-0 top-0"
                                 src="/Cardbg.svg"/>
                            <div
                                className="relative text-white text-start px-8 py-6 flex flex-col  w-full aspect-[388/138]">
                                <div className="flex items-center justify-start">
                                    <div className=""><span className="text-xs">Exch.</span> {showPrice.consumedPoints}
                                    </div>
                                    <div className="bg-white/10 font-semibold rounded-lg px-2 mx-2">POINT</div>
                                    <div> = ${showPrice.consumedPoints / pointsPerDols}</div>
                                </div>
                                <div>
                                    YOU PAY
                                </div>
                                <div className="ml-2 flex flex-row items-end">
                                    <div className="text-5xl">
                                        ${showPrice.consumedUsdt}
                                    </div>
                                    <div className="ml-2 text-2xl text-[#c8c0c0bd] line-through">${value}</div>
                                    {showPrice.remainingUsdt >= 0 ? (
                                        <button onClick={doIssue}
                                                className="ml-auto rounded-lg border border-white p-2">Redeem
                                        </button>
                                    ) : (
                                        <button onClick={() => doRecharge(showPrice.remainingUsdt * -1)}
                                                className="ml-auto rounded-lg border border-white p-2">Deposit
                                            ${showPrice.remainingUsdt * -1}</button>
                                    )}
                                </div>
                                {/*<p>总可用点数：{showPrice.remainingPoints + showPrice.consumedPoints}</p>*/}
                                {/*<p>消费点数：{showPrice.consumedPoints}</p>*/}
                                {/*<p>抵扣额度：{showPrice.consumedPoints / 5000}</p>*/}
                                {/*<p>需要支付usdt：{showPrice.consumedUsdt}</p>*/}
                                {/*<p>可用usdt：{showPrice.remainingUsdt + showPrice.consumedUsdt}</p>*/}
                                {/*<p>需要充值点数：{showPrice.remainingUsdt * -1}</p>*/}
                            </div>
                        </div>
                    </div>
                </>
            )}


        </React.Fragment>
    );
};

/**
 * 显示卡片消费价格
 */
async function queryPrice(brandSlug, amount, currency, telegramId) {
    console.log("start show price")
    const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/user/${telegramId}/`,
    );
    const {points: userPoints, usdt_mount: userUsdt} = response.data;
    return calcPrice(userUsdt, userPoints, amount);
}


async function issueCard(telegramId, brandSlug, amount, currency) {
    console.log("start issue card")
    const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/gift/deal`, {
            telegram_id: telegramId,
            brand: brandSlug,
            face_value: amount,
            currency: currency
        }, {}
    );
    console.log("issue card result", response.data);
    return response.data;
}


/**
 *
 * @param userUsdt 用户usdt余额
 * @param userPoints 用户点数
 * @param face_value 卡片面额
 */
function calcPrice(userUsdt, userPoints, face_value) {

    // 计算积分能抵扣多少元
    const pointsValue = Math.floor(userPoints / pointsPerDols);

    let consumedPoints = 0;
    let consumedUsdt = 0;

    if (pointsValue >= face_value) {
        // 积分足以完全抵扣
        consumedPoints = face_value * pointsPerDols;
        userPoints -= consumedPoints;
    } else {
        // 积分不足，先用积分抵扣尽可能多的，剩下的用USDT抵扣
        consumedPoints = pointsValue * pointsPerDols;
        userPoints -= consumedPoints;
        // 计算剩余需要用USDT支付的金额
        const remainingValue = face_value - pointsValue;
        consumedUsdt = remainingValue;
        userUsdt -= consumedUsdt;
    }

    return {
        consumedPoints,
        consumedUsdt,
        remainingPoints: userPoints,
        remainingUsdt: userUsdt
    };
}


export default ShopEarnGift;

/*
{
    "code": "000",
    "status": "success",
    "message": "Card created successfully",
    "data": {
        "brand": "hsamuel",
        "url": "https://sandbox.revealyourgift.com/4f5f0577-37d5-43cb-bc7f-391fbe8083d6/72de051e-d0d9-45d6-a86e-03f027ceed42",
        "face_value": {
            "amount": 1,
            "currency": "GBP"
        },
        "cost_value": {
            "amount": 0.93,
            "currency": "GBP"
        },
        "discount": 7.5,
        "expiration_date": "2026-03-19T17:51:38+00:00",
        "reference": "a5bee5b0-e619-11ee-9f3e-a3a1fe340e2d",
        "float_balance": {
            "amount": 999978.66,
            "currency": "GBP"
        }
    }
}
 */