import React, { useState, useEffect, useRef } from 'react'
import { Box, Alert, Button, Popover, Backdrop } from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'
import { getUserProfile } from '../middleware/userMiddleware'
import { LuckyWheel } from '@lucky-canvas/react'
import Navbar from '../components/Navbar'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import UserProfileCard from '../components/UserProfileCard'
import MoneyIcon from '../assets/images/money_icon.svg'
import MoneyCardIcon from "../assets/images/money_card_icon.svg";
import { useQuery } from '@tanstack/react-query'
import { toBase64 } from '../utils'

const GenerateReferLink = () => {
  const dispatch = useDispatch()
  const [isSpinning, setIsSpinning] = useState(false)
  const [result, setResult] = useState(null)
  const [stopAngle, setStopAngle] = useState(0)
  const [stopAngleAW, setStopAngleAW] = useState(0)
  const [anchorEl, setAnchorEl] = useState(null) // State for popover anchor element
  const [availableCount, setAvailableCount] = useState(null)
  const userProfile = useSelector((state) => state.user.userProfile)
  const auth = useSelector((state) => state.auth.access_token)
  const [prize, setPrize] = useState(null)
  const [prizeNumber, setPrizeNumber] = useState(null)
  const navigate = useNavigate()
  const myLucky = useRef()
  React.useEffect(() => {
    dispatch(getUserProfile())
  }, [dispatch])

  async function fetchData() {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/spin/${
          userProfile?.telegram_id || 1
        }/today`
      )
      if (response?.data?.status === 'success') {
        setAvailableCount(response.data.available_count)
      } else if (
        response?.data?.status === 'error' &&
        response?.data?.message
      ) {
        setShowAlert({ visible: true, title: response?.data?.message })
      }
    } catch (e) {
      console.log(e)
    }
  }

  const handleShare = () => {
    // const response = await axios.get(
    //   `${process.env.REACT_APP_API_URL}/user/${telegramId}/`,
    // );

    const referLink = `https://t.me/${process.env.REACT_APP_BOT_NAME}/${process.env.REACT_APP_BOT_MINIAPP}?startapp=${toBase64(JSON.stringify({
      referral: userProfile.referral,
    }))}`
    let telegramLink = "https://t.me/share/url?url=";
    telegramLink += encodeURIComponent(referLink);
    window.location.href = telegramLink;
  }

  useEffect(() => {
    if (auth) {
      fetchData()
    }
  }, [auth])

  const startSpin = async () => {
    console.log('startSpin');
    if (isSpinning) {
      return;
    }
    
    setIsSpinning(true);
    myLucky.current.play()
    let response
    try {
      response = await axios.post(
        `${process.env.REACT_APP_API_URL}/spin/v2`
      )
    } catch {
      navigate('/daily_spin')
      setShowAlert({ visible: true, title: 'A serious error is occured.' })
      myLucky.current.stop()
      setIsSpinning(false)
      return
    }
    console.log(response)
    fetchData()
    if (response?.data.status == 'error') {
      setShowAlert({ visible: true, title: response?.data?.message })
      myLucky.current.stop()
      return
    } else if (response.data.status == 'success') {
      const prizeMap = {
        point: 2,
      }
      setPrize(response.data.prize)
      myLucky.current.stop(prizeMap[response.data.prize.wheel_prize])
    }
  }
  // Open popover
  const handlePopoverOpen = () => {
    setAnchorEl(document.getElementById('spin-button'))
  }

  // Close popover
  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  // Check if popover is open
  const open = Boolean(anchorEl)

  const [showAlert, setShowAlert] = useState({ visible: false, title: '' })
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowAlert({ visible: false, title: '' })
    }, 5000)

    return () => clearTimeout(timer)
  }, [showAlert.visible])

  // const [rainking, setRainking] = useState([1, 2, 3, 4, 5, 6, 7])
  const [days, setdays] = useState([1, 2, 3, 4, 5, 6, 7])
  const [currentDay, setCurrentDay] = useState(0)
  const [dayElWidth, setDayElWidth] = useState(38)
  const [IsOpenShare, setIsOpenShare] = useState(false)
  const dayEl = useRef(null)
  useEffect(() => {
    if (dayEl.current) {
      setDayElWidth(dayEl.current.offsetWidth)
    }
  }, [])

  const { data: ranking, isLoading } = useQuery({
    queryKey: ['refer_ranking'],
    queryFn: async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/users/refer_ranking`
      );

      return response?.data?.users || [];
    }
  })


  return (
    <Box sx={{ position: 'relative' }}>
      {showAlert.visible && (
        <Alert
          severity="info"
          style={{
            position: 'absolute',
            top: '96px',
            width: '100%',
            margin: '12px',
            zIndex: 99,
            border: '1px solid #0098EA',
          }}
          onClose={() => {
            setShowAlert({ visible: false, title: '' })
          }}
        >
          {showAlert.title}
        </Alert>
      )}
      <Navbar />
      <UserProfileCard />
      <Box
        sx={{
          px: '21px',
          display: 'flex',
          flexDirection: 'column',
          // justifyContent: 'center',
          alignItems: 'center',
          mb: 4,
        }}
      >
        {/* Use the component with spinning animation */}
        <div className="flex justify-between w-full">
          <Box
            sx={{
              background: '#00000066',
              width: '52px',
              height: '20px',
            }}
            className="rounded-full flex justify-center items-center text-white text-xs font-semibold"
          >
            500 <img src={MoneyIcon} alt="icon" />
          </Box>
          <Box
            sx={{
              background: '#00000066',
              width: '52px',
              height: '20px',
            }}
            className="rounded-full flex justify-center items-center text-white text-xs font-semibold"
          >
            $0 <img src="/Ton_Icon.png" width={14} height={14} alt="icon" />
          </Box>
        </div>
        <LuckyWheel
          ref={myLucky}
          width="325px"
          height="325px"
          prizes={[
            { title: '0' },
            { title: '1' },
            { title: '2' },
            { title: '3' },
            { title: '4' },
            { title: '5' },
            { title: '6' },
            { title: '7' },
          ]}
          blocks={[
            { padding: '10px' },
            {
              padding: '10px',
              imgs: [
                {
                  src: '/spin_phone.png',
                  width: '100%',
                  rotate: true,
                },
              ],
            },
          ]}
          buttons={[
            {
              imgs: [
                {
                  src: '/spin_button.png',
                  width: '101.5px',
                  height: '130px',
                  top: '-75px',
                },
              ],
            },
          ]}
          onEnd={(prize) => {
            // 抽奖结束会触发end回调
            setIsSpinning(false)
            if (prize?.title) {
              setPrizeNumber(prize)
              handlePopoverOpen()
            }
          }}
        />
        <Backdrop
          sx={{ color: '#fff', zIndex: 99999 }}
          open={open}
          onClick={handlePopoverClose}
        >
          <Box
            sx={{
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-end',
              alignItems: 'center',
              width: '275px',
              height: '428px',
              background: `url(${
                prize?.wheel_prize === 'point' ? '/popup1.png' : '/popup2.png'
              }) no-repeat`,
              backgroundSize: 'contain',
            }}
          >
            <div
              style={{
                position: 'absolute',
                top: '71%',
                left: '50%',
                transform: 'translateX(-50%)',
                color: `${
                  prize?.wheel_prize === 'point' ? '#F99C39' : '#6167DF'
                }`,
              }}
            >
              {prize?.name.split(' ')[0]}
            </div>
            <div style={{ color: 'black' }} className="text-xs leading-5 mb-5">
              Congratulation! <br /> You just won {prize?.name} （TG Games）!
            </div>
          </Box>
        </Backdrop>
        <div className="flex flex-col gap-4 items-center w-full px-5 bottom-48">
          <Box
            sx={{
              background: '#9E9EA5',
              height: '28px',
            }}
            className="w-full relative flex items-center justify-around px-5"
          >
            {days.map((day, index) => (
              <div
                ref={dayEl}
                key={index}
                className={` text-xs flex-1 z-20 ${
                  currentDay === index ? 'text-black' : 'text-white'
                }`}
              >
                {currentDay === index ? 'Day ' + day : day}
              </div>
            ))}
            {currentDay >= 0 && (
              <div
                style={{
                  background:
                    'linear-gradient(86.14deg, #FFC747 6.13%, #E7A510 59.02%, #FEFF97 93.56%)',
                  borderTopRightRadius: '9999px',
                  borderBottomRightRadius: '9999px',
                  width: `${
                    currentDay === 6
                      ? '100%'
                      : (currentDay + 1) * dayElWidth + 20 + 'px'
                  }`,
                }}
                className="absolute left-0 top-0 h-full -z-10 !rounded-r-full px-4 transition-all"
              />
            )}
            <img
              style={{ transform: 'translate(-50%,-50%)' }}
              className="absolute left-0 top-1/2"
              width={43}
              height={45}
              src="/spin1.png"
              alt=""
            />
            <img
              style={{ right: 0, transform: 'translate(50%,-50%)' }}
              className="absolute top-1/2"
              width={43}
              height={45}
              src="/spin2.png"
              alt=""
            />
          </Box>
          <div className="flex flex-col items-center mb-4">
            <div className="font-semibold flex text-xl">
              Not enough
              <img
                className="mx-1"
                src={MoneyCardIcon}
                width={21}
                height={21}
                alt="ton-icon"
              />
              Coins
            </div>
            <div className="text-sm text-[#656565]">
              Play with friends and beat their score <br />
              or just challenge them
            </div>
          </div>
          <Button
            className="w-full poppines_font py-2 px-24 h-[48px]"
            sx={{
              background:
                'linear-gradient(87.02deg, #0098EA 19.69%, #32B6FD 93.81%)',
              borderRadius: '40px',
              fontWeight: '600',
              fontSize: '14px',
              textTransform: 'none',
            }}
            id="spin-button"
            onClick={startSpin}
            disabled={isSpinning || availableCount === null}
          >
            Spin for 50&nbsp;
            <img src={MoneyCardIcon} />
          </Button>
          <Button
            className="w-full h-[48px] poppines_font py-2 px-24 text-tgBlue"
            sx={{
              background: 'white',
              borderRadius: '40px',
              fontWeight: '600',
              fontSize: '14px',
              textTransform: 'none',
              border: '1px solid rgb(0,152,234)',
            }}
            id="spin-button"
            disabled={isSpinning || availableCount === null}
            onClick={handleShare}
          >
            {/* {buttonText()} */}
            Invitation to draw&nbsp;
            <img src={MoneyCardIcon} />
          </Button>
        </div>
      </Box>
      <Box
        sx={{
          background: 'linear-gradient(180deg, #F7F9FB 0%, #0098EA 100%)',
          pb: '120px',
        }}
        className="w-full px-5"
      >
        <div className="font-semibold text-xl mb-6">Invitation Ranking</div>

        <div className="flex justify-between w-full items-center text-[10px] mb-4 px-1">
          <div>Ranking&nbsp;&nbsp;&nbsp;User</div>
          <div>Invite</div>
          <div>Reward</div>
        </div>
        <div className="flex flex-col gap-3">
          {ranking?.map((item, index) => (
            <Box
              sx={{
                h: '52px',
                p: '16px 12px 16px 12px',
                borderRadius: '6px',
              }}
              key={index}
              className="bg-white flex-shrink-0 flex items-center justify-between"
            >
              <div style={{ width: '112px' }} className="flex items-center">
                <div>{index + 1}.</div>
                {index === 0 && (
                  <img
                    src="/champion.png"
                    alt="champion"
                    className="w-4 h-4 ml-2"
                  />
                )}
                {index === 1 && (
                  <img
                    src="/runner-up.png"
                    alt="runner-up"
                    className="w-4 h-4 ml-2"
                  />
                )}
                {index === 2 && (
                  <img
                    src="/third-runner-up.png"
                    alt="third-runner-up"
                    className="w-4 h-4 ml-2"
                  />
                )}
                <div className="ml-1">{item.username}</div>
              </div>
              <div className="flex items-center">
                <img
                  src="/fa-solid_users.png"
                  alt="users"
                  className="w-4 h-4 mr-1"
                />
                {item.invitation_count}
              </div>
              <div className="flex items-center">
                <img src={MoneyCardIcon} alt="star" className="w-4 h-4 mr-1" />
                {item.total_points}
              </div>
            </Box>
          ))}
        </div>
      </Box>
      <Box
        sx={{
          bottom: '82px',
          height: '80px',
          right: 4,
        }}
        className="fixed flex items-center"
      >
        {!IsOpenShare ? (
          <img
            style={{
              position: 'relative',
              right: '-12px',
            }}
            width={40}
            height={40}
            src="/spin_share_left1.png"
            alt="spin_share_left1"
            onClick={() => setIsOpenShare(true)}
          />
        ) : (
          <img
            style={{
              position: 'relative',
              right: '-35px',
            }}
            onClick={() => setIsOpenShare(false)}
            width={235}
            height={40}
            src="/spin_share_left2.png"
            alt="spin_share_left2"
          />
        )}
        <img
          style={{ marginTop: '10px', zIndex: 99, cursor: 'pointer' }}
          width={80}
          height={80}
          src="/spin_share.png"
          alt="spin_share"
          onClick={handleShare}
        />
      </Box>
    </Box>
  )
}

export default GenerateReferLink
