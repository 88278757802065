import React, { useState, useEffect } from "react";
import { Box, useTheme, Typography, Button } from "@mui/material";
import { getUserProfile } from "../middleware/userMiddleware";
import { useSelector, useDispatch } from "react-redux";
import { setFooterState } from "../features/footerSlice";

import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import MoneyCard from "../components/MoneyCard";
import ProfileIcon from "../assets/images/profile.png";
import Flag from "../assets/images/France_Flag.svg";
import MoneyIcon from "../assets/images/money_icon.svg";
import MoneyCardIcon from "../assets/images/money_card_icon.svg";
import Crown from "../assets/images/Crown.svg";
import HalfCrown from "../assets/images/HalfCrown.svg";
import Sunlight from "../assets/images/sunlight.svg";
import CoinsGroup from "../assets/images/coin_group.png";
import SSquare from "../assets/images/SmSquare.svg";
import BSquare from "../assets/images/BigSquare.svg";

import ShopEarnCard from "../components/ShopEarnCard";
import ShopEarnGift from "../components/ShopEarnGift";
import NFTCard from "../components/NFTCard";
import {
  ShopEarnData,
  ShopEarnGiftData,
  Countries,
  LanguageFlags,
  FooterUrl,
  NFTData,
} from "../Constant";
import { v4 as uuidV4 } from "uuid";
import axios from "axios";
import CryptoJS from "crypto-js";
import {LoaderIcon} from "lucide-react";
import UserProfileCard from "../components/UserProfileCard";

import BCrawn from "../assets/images/b_crawn.svg";
import BHCrawn from "../assets/images/b_half_crawn.svg";
import { useNavigate } from "react-router-dom";

const ShopEarn = (props) => {
  const theme = useTheme();
  const [selectedButton, setSelectedButton] = useState(props.type);
  const [brands, setBrands] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentUrl =
    window.location.href.split("/")[window.location.href.split("/").length - 1];

  useEffect(() => {
    dispatch(
      setFooterState(FooterUrl["/shop_earn_gift"])
    );

    fetchCards().then(brands => {
      setBrands(brands);
    }).catch(error=>{
      console.error('brand  list fail');
    })
  }, []);

  const handleNavigate = (value) => {
    navigate('/eco', {
      state: {
        id: value
      }
    });
  };

  console.log('props', props)

  useEffect(() => {
    dispatch(getUserProfile());
  }, [dispatch]);

  const handleSetSelectedButton = (button) => {
    setSelectedButton(button);
  };

  const getGiftCards = async () => {
    handleSetSelectedButton("gift");
    // try {
    //     fetchCards().then(brands=>{
    //         setBrands(brands);
    //     })
    // } catch (error) {
    //   console.error('brand  list fail');
    // } finally {
    //   handleSetSelectedButton("gift");
    // }
  };

  return (
    <React.Fragment>
      <Navbar />
      <UserProfileCard />
      <Box className="flex-1" sx={{ width: "100%", padding: "0 21px", textAlign: "center" }}>
        <Box sx={{ my: "20px" }}>
          <p
            style={{
              lineHeight: "24px",
              fontSize: "18px",
              fontWeight: "600",
              color: "rgba(37, 41, 70, 1)",
            }}
          >
            Shop to Earn
          </p>
        </Box>
        <Box
          sx={{
            borderRadius: "100px",
            padding: "4px 6px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: "rgba(241, 241, 242, 1)",
            marginBottom: "25px",
          }}
        >
          <Box
            sx={{
              width: "50%",
              borderRadius: "20px",
              background:
                selectedButton === "gift" ? "rgba(0, 152, 234, 1)" : "unset",
              cursor: "pointer",
              padding: "4px",
            }}
            onClick={getGiftCards}
          >
            <p
              style={{
                color:
                  selectedButton === "gift"
                    ? "white"
                    : "rgba(117, 117, 122, 1)",
                fontSize: "12px",
              }}
            >
              Gift-cards
            </p>
          </Box>
          <Box
            sx={{
              width: "50%",
              borderRadius: "20px",
              background:
                selectedButton === "deals" ? "rgba(0, 152, 234, 1)" : "unset",
              cursor: "pointer",
              padding: "4px",
            }}
            onClick={() => handleSetSelectedButton("deals")}
          >
            <p
              style={{
                color:
                  selectedButton === "deals"
                    ? "white"
                    : "rgba(117, 117, 122, 1)",
                fontSize: "12px",
              }}
            >
              Ton ecosystem
            </p>
          </Box>
        </Box>
        {selectedButton === "deals" ? (
          <>
            <Box>
              {NFTData.map((item, index) => (
                <NFTCard
                  key={index}
                  bg={item.bg}
                  icon={item.icon}
                  title={item.title}
                  amount={item.amount}
                  url={item.url}
                  func={handleNavigate}
                  index={item.index}
                />
              ))}
            </Box>
            {/* <Box
              sx={{
                width: "100%",
                padding: "7px 150px 18px 16px",
                background:
                  "linear-gradient(0deg, rgba(14, 132, 196, 0.15), rgba(14, 132, 196, 0.15)),linear-gradient(82.05deg, #19A4FF 5.49%, #4992FE 48.57%, #FFF5C2 96.19%)",
                borderRadius: "18px",
                overflow: "hidden",
                position: "relative",
                marginBottom: "23px",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <p
                  style={{
                    fontSize: "12px",
                    fontWeight: "600",
                    lineHeight: "29px",
                    color: "white",
                    marginRight: "10px",
                    textTransform: "uppercase",
                  }}
                >
                  Category{" "}
                </p>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <img style={{ width: "11.93" }} src={Crown} alt="Crown" />
                  <img style={{ width: "10.11" }} src={HalfCrown} alt="Crown" />
                  <img style={{ width: "10.11" }} src={HalfCrown} alt="Crown" />
                </Box>
              </Box>
              <Box>
                <p
                  style={{
                    textAlign: "left",
                    fontSize: "19px",
                    lineHeight: "25px",
                    fontWeight: "700",
                    color: "white",
                    textTransform: "uppercase",
                  }}
                >
                  Earn coins <br />
                  while you shop!
                </p>
              </Box>
              <img
                style={{
                  position: "absolute",
                  top: "0",
                  right: "0",
                  zIndex: 2,
                }}
                src={Sunlight}
                alt="SunLight"
              />
              <img
                style={{
                  position: "absolute",
                  top: "10px",
                  right: "0",
                  zIndex: 3,
                }}
                src={CoinsGroup}
                alt="SunLight"
              />
              <img
                style={{
                  position: "absolute",
                  opacity: 0.2,
                  top: "-17px",
                  right: "160",
                  zIndex: 1,
                }}
                src={SSquare}
                alt="SunLight"
              />
              <img
                style={{
                  position: "absolute",
                  opacity: 0.2,
                  top: "85px",
                  right: "153px",
                  zIndex: 1,
                }}
                src={SSquare}
                alt="SunLight"
              />
              <img
                style={{
                  position: "absolute",
                  opacity: 0.2,
                  top: "-61px",
                  right: "53px",
                  zIndex: 1,
                }}
                src={BSquare}
                alt="SunLight"
              />
              <img
                style={{
                  position: "absolute",
                  opacity: 0.2,
                  top: "-23px",
                  left: "-71px",
                  zIndex: 1,
                }}
                src={BSquare}
                alt="SunLight"
              />
            </Box>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns:
                  "minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr)",
                gap: "7px",
              }}
            >
              {ShopEarnData.map((item, index) => (
                <ShopEarnCard
                  key={index}
                  title={item.title}
                  icon={item.icon}
                  top={item.top}
                  left={item.left}
                  bgColor={item.bgColor}
                  url={item.url}
                  width={item?.width}
                />
              ))}
            </Box> */}
          </>
        ) : (
            <>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "minmax(0, 1fr) minmax(0, 1fr)",
              gap: "10px",
            }}
          >
              {brands.map((item,index) => (
                  <ShopEarnGift  key={index}  data={item}  />
              ))}


          </Box>
            </>
        )}
      </Box>
    </React.Fragment>
  );
};


async function fetchCards(){
    const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/gift/list`
    );
    const result=[];
    for (let key in response.data.data.brands) {
        result.push(response.data.data.brands[key]);
    }
   return result;
}
export default ShopEarn;

/*
{
    "code": "000",
    "status": "success",
    "message": "Brands information with details",
    "data": {
        "brands": {
            "b-and-m": {
                "slug": "b-and-m",
                "name": "B&M",
                "type": "gift-card",
                "status": {
                    "code": "ENABLED"
                },
                "currency": "GBP",
                "discount": 7,
                "last_updated": "2024-01-23T14:23:09+00:00",
                "transaction_types": [
                    "digital_issuance"
                ],
                "delivery_methods": [
                    "url"
                ],
                "countries_served": [
                    "GB"
                ],
                "gc_pool": false,
                "async_only": true,
                "categories": [
                    "baby",
                    "beauty",
                    "cars",
                    "craft",
                    "home",
                    "other",
                    "toys"
                ],
                "vat": {
                    "exempt": false,
                    "type": "MPV",
                    "rate": "20.00"
                },
                "digital_face_value_limits": {
                    "lower": 10,
                    "upper": 1000,
                    "minor_unit": 0.01
                },
                "detail": {
                    "assets": {
                        "logo_url": "https://assets.tillo.dev/images/brands/logos/180x180/b-and-m.jpg",
                        "gift_card_url": "https://assets.tillo.dev/images/brands/vouchers/330x214/b-and-m.jpg"
                    },
                    "barcode": "NONE",
                    "description": "The UK's fastest-growing variety retailer, with over 4 million shoppers per week, B&M believes in selling Big Brands at sensational prices.\n                                B&M was founded in 1978 and is now the leading variety retailer in the UK.\n                                From its first store in Blackpool, Lancashire, B&M has grown to over 685+ stores and employs over 35,000+ staff. B&M believes in selling top branded products at sensational prices. \n                                B&M has stores throughout England, Scotland, Wales and Northern Ireland.",
                    "expiry": "24 months from purchase",
                    "redemption_methods": [
                        "Instore"
                    ],
                    "terms_and_conditions_copy": "This Digital Gift Card can be redeemed for merchandise in UK stores and cannot be exchanged for cash.\n                            No change or refunds will be given against this Digital Gift Card.\n                            You can check out your balance at any checkout in store or by calling...\n                            The Digital Gift Card is valid for a period of 24 months from the date of purchase and any unused balance will be lost after this date.\n                            Please protect this Digital Gift Card and treat as cash.\n                            It cannot be replaced if lost or stolen.\n                            This Digital Gift Card has a minimum load of £10 and maximum load and balance value of £1,000.\n                            B&M reserves the right at its sole discretion to amend the terms and conditions of this Digital Gift Card at anytime and take appropriate action including the cancellation of this Digital Gift Card. \n                            This does not affect your statutory rights.",
                    "website_url": "https://www.bmstores.co.uk/"
                }
            }
        },
        "last_refreshed_at": "2024-03-19T16:06:52+00:00"
    }
}
 */