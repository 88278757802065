import React, { useEffect, useState } from "react";
import { Box, Hidden, useTheme } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setBackState, increaseIndex } from "../features/navbarSlice";

import CrownIcon from "../assets/images/Crown.svg";
import WhiteCrown from "../assets/images/HalfCrownWhite.svg";
import EmptyCrown from "../assets/images/HalfCrown.svg";
import MoneyCardIcon from "../assets/images/money_card_icon.svg";
import SSquare from "../assets/images/SmSquare.svg";
import BSquare from "../assets/images/BigSquare.svg";
import ArrowRight from "../assets/images/rightArrow.svg";

const PointSystemCard = (props) => {
  const currentFooter = useSelector((state) => state.backState.value);
  const dispatch = useDispatch();
  const theme = useTheme();
  const navigate = useNavigate();
  const handleClickEvent = (url) => {
    const currentUrl =
      window.location.href.split("/")[
        window.location.href.split("/").length - 1
      ];
    dispatch(
      setBackState(
        currentUrl == "" ||
          currentUrl == " " ||
          currentUrl == "undefined" ||
          currentUrl == null
          ? "/"
          : "/" + currentUrl
      )
    );
    dispatch(increaseIndex());
    navigate(url);
  };
  return (
    <React.Fragment>
      <Box
        className="flex-1 h-auto"
        sx={{ cursor: "pointer" }}
        onClick={() => handleClickEvent(props.redirectUrl)}
      >
        <Box
          className="h-full"
          sx={{
            display: "flex",
            flexDirection: "column",
            background: props.bgColor,
            borderRadius: "13.6px",
            padding: "11px",
            overflow: "hidden",
            position: "relative",
          }}
        >
          <img
            style={{
              position: "absolute",
              width: "43px",
              left: "70px",
              top: "21px",
              opacity: 0.4,
            }}
            src={SSquare}
            alt="Square"
          />
          <img
            style={{
              position: "absolute",
              width: "43px",
              top: "111px",
              left: "86px",
              opacity: 0.4,
            }}
            src={SSquare}
            alt="Square"
          />
          <img
            style={{
              position: "absolute",
              width: "105px",
              left: "-54px",
              top: "20px",
              opacity: 0.4,
            }}
            src={BSquare}
            alt="Square"
          />
          <img
            style={{
              position: "absolute",
              width: "105px",
              top: "-11px",
              left: "92px",
              opacity: 0.4,
            }}
            src={BSquare}
            alt="Square"
          />
          <div className="w-full flex flex-col h-full items-center justify-between">
            <p className="font-bold text-xs text-white text-center">
              {props.text}
            </p>
            <img className="mt-auto"
              style={{
                height: 80,
              }}
              src={props.icon}
              alt="Main Icon"
            />
          </div>
          {/* <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "space-between",
              position: "relative",
            }}
          >
            <img className="absolute "
              style={{
                width: props.width,
                left: '50%',
                top: props?.top,
              }}
              src={props.icon}
              alt="Main Icon"
            />
          </Box> */}
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default PointSystemCard;
