import React, { Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import {
  Box,
  useTheme,
  Button,
  Typography,
  Alert,
  Drawer,
  TextField,
  Grid,
  CircularProgress,
} from "@mui/material";
import Iframe from "react-iframe";
import { useSelector, useDispatch } from "react-redux";
import { setGameState } from "../features/gameSlice";
import { setBackState, increaseIndex } from "../features/navbarSlice";
import { getUserProfile } from "../middleware/userMiddleware";

import Navbar from "../components/Navbar";
import PointSystemCard from "../components/PointSystemCard";

import axios from "axios";
import { setFooterState, setFooterHidden } from "../features/footerSlice";
import { FooterUrl } from "../Constant";
import { useQuery } from "@tanstack/react-query";

const handledoubleclick = (cb, delay) => {
  let lasttime = Date.now();
  return () => {
    if (Date.now() - lasttime < delay) {
      cb();
    }
    lasttime = Date.now();
  };
};

const Game = () => {
  const [tooltipTitle, setTooltipTitle] = useState("");
  const dispatch = useDispatch();
  const access_token = useSelector(store => store.auth.access_token)

  const [search] = useSearchParams();
  const location = useLocation();
  const id = search.get('id');

  React.useEffect(() => {
    dispatch(setBackState(location?.state?.backUrl || "/"));
    dispatch(increaseIndex());
  }, []);

  const { data, isLoading } = useQuery({
    queryKey: ['game', {
      id: id
    }],
    queryFn: async () => {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/game/${id}`);
      return response?.data?.game;
    },
    enabled: !!id
  })

  // elastic modal
  const [isGaming, setIsGaming] = useState(false);
  const showGameWithDelay = handledoubleclick(() => {
    setIsGaming(false);
  }, 500);

  // others
  useEffect(() => {
    dispatch(setFooterHidden(true));
    dispatch(getUserProfile());
  }, [dispatch]);

  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowAlert(false);
    }, 5000);

    return () => clearTimeout(timer);
  }, [showAlert]);

  console.log('data', data)

  return (
    <div className="w-screen h-screen flex flex-col">
      {/*顶部导航 只在游戏内显示*/}
      {<Navbar />}
      {showAlert && (
        <Alert
          severity="info"
          style={{ position: "absolute", top: "96px", zIndex: 99 }}
          onClose={() => setShowAlert(false)}
        >
          {`${tooltipTitle} copied!`}
        </Alert>
      )}
      {isLoading && <div className="flex-1 bg-slate-900"></div>}
      {data && <Iframe
        url={data.url + '?token=' + access_token}
        className="w-full h-full"
        display="block"
        position="relative"
        scrolling="no"
        frameBorder={0}
        onMouseOver={() => {
          showGameWithDelay();
        }}
      />}
    </div>
  );
};

export default Game;
