// middleware/userMiddleware.js
import axios from "axios";
import { setUserProfile } from "../features/userSlice";

export const getUserProfile = () => async (dispatch) => {
  const user = window?.Telegram?.WebApp?.initDataUnsafe?.user || {
    username: 'kiddyu',
    id: 1603522106
  };
  const avatar = `https://api.multiavatar.com/${user.id}.png`;
  
  // let username;
  // let avatar = '/static/media/profile.10706be277f89a5816f1.png';

  // try {
  //   const response = await axios.get(`https://api.telegram.org/bot${process.env.REACT_APP_TELEGRAM_BOT_TOKEN}/getChat?chat_id=${user?.id}`);
  //   username = response.data.result.username || 'No name';
  //   if (response.data.result.photo && response.data.result.photo.small_file_id) {
  //     const fileId = response.data.result.photo.small_file_id;
  //     const response = await axios.get(`https://api.telegram.org/bot${process.env.REACT_APP_TELEGRAM_BOT_TOKEN}/getFile?file_id=${fileId}`);
  //     const filePath = response.data.result.file_path;
  //     avatar = `https://api.telegram.org/file/bot${process.env.REACT_APP_TELEGRAM_BOT_TOKEN}/${filePath}`;
  //   } else {
  //     // Handle the case where small_file_id is empty
  //     // You can set a default avatar or handle it in a way that suits your application
  //   }
  // } catch (error) {
  //   console.error('Error fetching avatar:', error);
  // }

  dispatch(setUserProfile({
    telegram_id: user.id,
    username: user.username,
    avatar: avatar
  }));
};

const userMiddleware = (store) => (next) => (action) => {
  next(action);
};

export default userMiddleware;
